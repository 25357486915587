import React, { useEffect } from "react";
import { TextField } from "@cpchem/covalence-ui";

import "./styles.scss";

interface CommentsInputProps {
    onHandleChange: (comment: string) => void;
    comment: string;
    testId?: string;
    hideLabel?: boolean;
}

export function CommentsInput({
    onHandleChange,
    testId,
    comment,
    hideLabel,
}: CommentsInputProps) {
    useEffect(
        () => {
            return () => {
                onHandleChange("");
            };
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    return (
        <div className="comments-input-container" data-testid={testId}>
            <TextField
                label={"Add Comment"}
                onChange={onHandleChange}
                value={comment}
                isMultiline
                testId={`${testId}-textfield`}
                isLabelHidden={hideLabel}
            />
        </div>
    );
}
