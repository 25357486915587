import {
    FetchOptionCredential,
    FetchOptionMethod,
    FetchOptionMode,
    FetchOptions,
} from "@services/fetch-interfaces";

export interface FetchInterceptorService {
    getFetchOptionsAsync(
        scopes: string[],
        method: FetchOptionMethod,
        body?: string | FormData,
        credentials?: FetchOptionCredential,
        mode?: FetchOptionMode
    ): Promise<FetchOptions>;
}

export const FetchServiceKey = "FetchInterceptor";
