import React, { memo } from "react";
import { Review } from "@services/cerberus/model";
import { HeaderGroup, flexRender } from "@tanstack/react-table";
import { TestIds } from "@pages/user-access-reviews-page/test-ids";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faArrowDown,
    faArrowUp,
    faArrowUpArrowDown,
} from "@fortawesome/pro-light-svg-icons";

interface TableHeadProps {
    headerGroup: HeaderGroup<Review>;
    testId?: string;
}

function TableHead({ headerGroup, testId }: TableHeadProps) {
    return (
        <tr
            key={headerGroup.id}
            data-testid={`${testId}-${TestIds.ReviewsTableHeadRow}`}
        >
            {headerGroup.headers.map((header) => (
                <th
                    key={header.id}
                    colSpan={header.colSpan}
                    style={{
                        width: header.getSize(),
                    }}
                    data-testid={`${testId}-${TestIds.ReviewsTableHeadRow}-${header.id}`}
                >
                    {header.isPlaceholder ? null : (
                        <div
                            {...{
                                className: header.column.getCanSort()
                                    ? "cursor-pointer"
                                    : "",
                                onClick:
                                    header.column.getToggleSortingHandler(),
                            }}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    header.column.getToggleSortingHandler();
                                }
                            }}
                            role="button"
                            tabIndex={0}
                        >
                            {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                            )}
                            {{
                                asc: (
                                    <FontAwesomeIcon
                                        icon={faArrowUp}
                                        className="sort-icon"
                                    />
                                ),
                                desc: (
                                    <FontAwesomeIcon
                                        icon={faArrowDown}
                                        className="sort-icon"
                                    />
                                ),
                            }[header.column.getIsSorted() as string] ?? (
                                <FontAwesomeIcon
                                    icon={faArrowUpArrowDown}
                                    className="sort-icon"
                                />
                            )}
                        </div>
                    )}
                </th>
            ))}
        </tr>
    );
}

export default memo(TableHead);
