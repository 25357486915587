import React, { useState } from "react";
import { faBusinessTime } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CreateCampaignViewTestIds } from "../../../../test-ids";
import { Button } from "@cpchem/covalence-ui";
import { StateModal } from "@components";
import {
    createCampaignDangerModalState,
    createCampaignSuccessModalState,
} from "./constants";
import CreateCampaignModal from "./components/create-campaign-modal/create-campaign-modal";
import { CerberusService, CerberusServiceKey } from "@services";
import { useNavigate } from "react-router-dom";
import { useService } from "../../../../../../service-provider";

import "./styles.scss";
import { ModalState } from "@components/state-modal/constants";

interface CreateCampaignViewProps {
    testId?: string;
}

function CreateCampaignView({ testId }: CreateCampaignViewProps): JSX.Element {
    const [createCampaignModalIsOpen, setCampaignModalIsOpen] = useState(false);
    const [stateModalVisible, setStateModalVisible] = useState(false);
    const [modalState, setModalState] = useState<ModalState | null>(null);

    const cerberusService = useService<CerberusService>(CerberusServiceKey);
    const navigate = useNavigate();

    async function handleCreateCampaign(dueDate: Date, ADUserFile: File) {
        try {
            const response = await cerberusService.CreateCampaign({
                dueDate,
                file: ADUserFile,
            });

            if (response.data) {
                showCampaignCreateSuccess();
                return;
            }
            showCampaignCreateError(response.error);
        } catch (error) {
            showCampaignCreateError(error);
        }
    }

    function onCreateCampaign() {
        setCampaignModalIsOpen(true);
    }

    function onCloseCreateCampaignModal() {
        setCampaignModalIsOpen(false);
    }

    function showCampaignCreateSuccess() {
        setModalState({
            ...createCampaignSuccessModalState,
            onClose: () => {
                setStateModalVisible(false);
                // Refresh the page
                navigate(0);
            },
        });
        setStateModalVisible(true);
    }

    function showCampaignCreateError(error: unknown) {
        setModalState({
            ...createCampaignDangerModalState,
            contentText: `${error}`,
            onClose: () => {
                setStateModalVisible(false);
            },
        });
        setStateModalVisible(true);
    }
    return (
        <div className="create-campaign-view" data-testid={testId}>
            <FontAwesomeIcon icon={faBusinessTime} className="icon" />
            <p className="no-data-message">No Campaigns</p>
            <p className="hint-message">
                To initiate a new review, simply click the &apos;Create
                Campaign&apos; button below!
            </p>
            <Button
                className="create-button"
                color="primary"
                variant="solid"
                text="Create Campaign"
                onClick={onCreateCampaign}
                testId={CreateCampaignViewTestIds.CreateCampaignButton}
            />
            <CreateCampaignModal
                testid={CreateCampaignViewTestIds.CreateCampaignModal}
                onStartCampaign={handleCreateCampaign}
                onClose={onCloseCreateCampaignModal}
                isOpen={createCampaignModalIsOpen}
            />
            {stateModalVisible && modalState && (
                <StateModal
                    {...modalState}
                    isOpen={stateModalVisible}
                    testId={CreateCampaignViewTestIds.StateModal}
                />
            )}
        </div>
    );
}

export default CreateCampaignView;
