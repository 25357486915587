import React from "react";
import { TestIds } from "@pages/user-access-reviews-page/test-ids";
import { StatsAndTableView } from "../stats-and-table-view";

import "./styles.scss";

export function MyReviewsView(): JSX.Element {
    return (
        <div className="my-reviews-view" data-testid={TestIds.MyReviewsView}>
            <StatsAndTableView
                testId={TestIds.MyReviewsView}
                showInfoMessage
                showReviewsAssignedToMe
            />
        </div>
    );
}
