import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./app";
import Config from "./config";
import { LoginScreen, UnauthenticatedPage } from "./pages";
import { AuthManager } from "@cpchem/azure-auth";
import { AuthenticationBoundary } from "@cpchem/azure-auth-react";
import { trackEvent } from "@cpchem/logging";
import { configureLogging } from "./logging/configure-logging";
import { configureLogRocket } from "./logging/configure-log-rocket";
import {
    ServiceLocator,
    PersonalizationServiceKey,
    AuthServiceKey,
    CerberusServiceKey,
} from "@services";
import {
    FetchInterceptorImplementation,
    FetchServiceKey,
} from "@services/fetch-interceptor";
import { PersonalizationServiceImplementation } from "@services/personalization";
import { AlertBannerProvider, Drawer, Modal } from "@cpchem/covalence-ui";
import "./index.scss";
import { getDevAccountMock } from "./utilities/use-devmode-user";
import { CerberusServiceImplementation } from "@services/cerberus";
import { ServiceProvider } from "./service-provider";

configureLogging(Config);

const authSettings = {
    clientId: Config.msal.auth.clientId,
    authority: Config.msal.auth.authority ?? "",
    redirectUri: Config.msal.auth.redirectUri ?? "",
    scopes: [...Config.api.personalization.scopes],
    domainHint: "cpchem.com",
};
let authManagerInstance: AuthManager;
if (!Config.connected) {
    authManagerInstance = AuthManager.getInstance();
    const mockAccountGenerator = await getDevAccountMock();
    authManagerInstance.loadConfiguration(
        "Dev",
        undefined,
        mockAccountGenerator
    );

    // Faked APIs
    const { worker } = await import("./api-mocks/browser");
    worker.start();
} else {
    let domainHint = "cpchem.com";
    if (Config.env === "development") {
        domainHint = "jonthenerd3.onmicrosoft.com";
    }
    authSettings.domainHint = domainHint;
    authManagerInstance = AuthManager.getInstance();
    authManagerInstance.loadConfiguration("MSAL", authSettings);

    configureLogRocket(Config.logging.logRocketAppId);
}

trackEvent({
    type: "PageView",
    contents: JSON.stringify({
        page: "SPA Template",
        params: { date: Date.now() },
    }),
});

// Configure Services in Service Locator
const locator = ServiceLocator;
locator.set(AuthServiceKey, authManagerInstance);
locator.set(FetchServiceKey, new FetchInterceptorImplementation());
locator.set(CerberusServiceKey, new CerberusServiceImplementation());
locator.set(
    PersonalizationServiceKey,
    new PersonalizationServiceImplementation()
);

const rootElement = document.getElementById("root");
if (!rootElement) throw new Error("Failed to find the root element");
if (rootElement) {
    Modal.setAppElement(rootElement);
}
const root = ReactDOM.createRoot(rootElement);
Drawer.setAppElement(rootElement);

const app = (
    <ServiceProvider serviceLocator={locator}>{<App />}</ServiceProvider>
);

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <AlertBannerProvider>
                <AuthenticationBoundary
                    showOnUnknown={<LoginScreen />}
                    showOnAuthenticated={app}
                    showOnUnAuthenticated={<UnauthenticatedPage />}
                />
            </AlertBannerProvider>
        </BrowserRouter>
    </React.StrictMode>
);
