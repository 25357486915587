import React from "react";
import { Avatar } from "@cpchem/covalence-ui";
import { getColorFromName } from "@utilities/random";
import { getInitialsFromName } from "@utilities/text";

import "./styles.scss";

interface DetailedAvatarProps {
    name: string;
    userName?: string;
    avatarUrl?: string | null;
    randomizeColor?: boolean;
    className?: string;
    timeStamp?: string;
    testId?: string;
}

export function DetailedAvatar({
    avatarUrl,
    className,
    name,
    randomizeColor,
    userName,
    timeStamp,
    testId: incomingTestId,
}: DetailedAvatarProps) {
    let cn = "detailed-avatar";

    if (className) {
        cn += ` ${className}`;
    }

    if (randomizeColor) {
        cn += ` ${getColorFromName(name)}`;
    }

    const testId = incomingTestId || "detailed-avatar";

    return (
        <div
            className={cn}
            key={`${name}-${new Date().valueOf()}`}
            data-testid={testId}
        >
            <Avatar
                src={avatarUrl || undefined}
                initials={getInitialsFromName(name)}
                className="avatar"
                data-testid={`${testId}-icon`}
                alt="avatar"
            />
            <div className="user-details">
                <p className="name" data-testid={`${testId}-display-name`}>
                    {name}
                </p>
                {userName && (
                    <p className="username" data-testid={`${testId}-username`}>
                        {userName}
                    </p>
                )}
                {timeStamp && (
                    <p
                        className="timestamp"
                        data-testid={`${testId}-timestamp`}
                    >
                        {new Date(timeStamp).toLocaleString()}
                    </p>
                )}
            </div>
        </div>
    );
}
