import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { AlertBanner, Grid } from "@cpchem/covalence-ui";
import { UserAccessReviewsPage } from "../pages";
import { AppBar } from "./components/template-app-bar";
import { routePath } from "./route-paths";
import { withFlagProvider } from "@cpchem/featureflags";
import CurrentCampaignView from "@pages/user-access-reviews-page/components/current-campaign-view";
import { MyReviewsView } from "@pages/user-access-reviews-page/components/my-reviews-view";
import { ClosedCampaignsView } from "@pages/user-access-reviews-page/components/closed-campaigns-view";

export const clientSideId = "626c04c8e959001303dc3dd3";

export function App(): JSX.Element {
    return (
        <Grid className="app">
            <AppBar />
            <AlertBanner />
            <div className="main">
                <Routes>
                    <Route
                        path={routePath.USER_ACCESS_REVIEWS}
                        element={<UserAccessReviewsPage />}
                    >
                        <Route
                            index
                            path={routePath.USER_ACCESS_REVIEWS}
                            element={<MyReviewsView />}
                        />
                        <Route
                            path={routePath.UAR_CAMPAIGNS_CURRENT}
                            element={<CurrentCampaignView />}
                        />
                        <Route
                            path={routePath.UAR_CAMPAIGNS_CLOSED}
                            element={<ClosedCampaignsView />}
                        />
                    </Route>
                    <Route
                        path="*"
                        element={
                            <Navigate
                                to={routePath.USER_ACCESS_REVIEWS}
                                replace
                            />
                        }
                    />
                </Routes>
            </div>
        </Grid>
    );
}

export default withFlagProvider({
    clientSideID: clientSideId,
    user: {
        key: "example_user",
        name: "Example User",
        email: "User@example.com",
    },
})(App);
