import { createColumnHelper } from "@tanstack/react-table";
import {
    repositoryRender,
    reviewedByRender,
    decisionStatusRender,
    commentsCountRender,
} from "./cell-renders";
import {
    decisionStatusSortFn,
    commentsCountSortFn,
    repositoryNameSortFn,
} from "./sorts";
import { Review } from "@services/cerberus/model";

export const columnHelper = createColumnHelper<Review>();

export const desktopColumns = [
    columnHelper.accessor("repositoryName", {
        header: () => "Repository",
        cell: (info) => {
            const { repositoryName, repositoryDescription } = info.row.original;
            return repositoryRender({
                repositoryName,
                repositoryDescription,
            });
        },
        sortingFn: repositoryNameSortFn,
        size: 250,
    }),
    columnHelper.accessor("reviewedBy.displayName", {
        header: () => "Reviewer",
        cell: (info) => {
            const { reviewedBy } = info.row.original;

            return reviewedByRender(reviewedBy);
        },
    }),
    columnHelper.accessor("decisionStatus", {
        header: () => "Decision",
        cell: (info) => {
            const { decisionStatus } = info.row.original;

            return decisionStatusRender(decisionStatus);
        },
        size: 100,
        sortingFn: decisionStatusSortFn,
        sortDescFirst: false,
    }),
    columnHelper.accessor("commentsCount", {
        header: () => "Comments",
        cell: (info) => {
            const { commentsCount } = info.row.original;

            return commentsCountRender(commentsCount);
        },
        size: 100,
        sortingFn: commentsCountSortFn,
        sortDescFirst: false,
    }),
];

export const mobileColumns = [
    columnHelper.accessor("repositoryName", {
        header: () => "Repository",
        cell: (info) => {
            const { repositoryName } = info.row.original;
            return repositoryRender({
                repositoryName,
            });
        },
    }),
    columnHelper.accessor("decisionStatus", {
        header: () => "Decision",
        cell: (info) => {
            const { decisionStatus } = info.row.original;

            return decisionStatusRender(decisionStatus);
        },
        sortingFn: decisionStatusSortFn,
        sortDescFirst: false,
    }),
];
