/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { faListCheck, faClock, faLock } from "@fortawesome/pro-solid-svg-icons";
import { SideBar } from "@components/side-bar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadingIndicator, StateModal } from "@components";
import { trackEvent } from "@cpchem/logging";
import { TestIds } from "./test-ids";
import { Authorized, Restricted, useAuth } from "@cpchem/azure-auth-react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { routePath } from "../../app/route-paths";
import { SideBarItems, routeLocationMap } from "./constants";
import { useService } from "../../service-provider";
import { CerberusService, CerberusServiceKey } from "@services";
import { Campaign, Reviewer } from "@services/cerberus/model";
import useMediaQuery from "../../hooks/use-media-query";
import { ModalState } from "@components/state-modal/constants";

import "./styles.scss";
import { GetClosedCampaignsAPIResponse } from "@services/cerberus/interface";

export function UserAccessReviewsPage(): JSX.Element {
    let cn = "user-access-reviews-page";

    const cerberusService = useService<CerberusService>(CerberusServiceKey);

    const { currentAccount } = useAuth({});

    const isOwner = currentAccount?.roles?.includes("Owners");

    const isMobileScreen = useMediaQuery("(max-aspect-ratio: 9/16)");

    const navigate = useNavigate();

    const location = useLocation();

    const [isLoading, setIsLoading] = useState(false);

    const [activeCampaign, setActiveCampaign] = useState<Campaign | null>(null);

    const initialData: GetClosedCampaignsAPIResponse = [];
    const [closedCampaigns, setClosedCampaigns] =
        useState<GetClosedCampaignsAPIResponse>(initialData);

    const [stateModal, setStateModal] = useState<ModalState>();

    const [isStateModalOpen, setIsStateModalOpen] = useState(false);

    const [reviewer, setReviewer] = useState<Reviewer | null>(null);

    const [closedCampaignId, setClosedCampaignId] = useState<string | null>(
        null
    );

    function onSetLoading(isLoading: boolean) {
        setIsLoading(isLoading);
    }

    function onSetStateModal(modalState: ModalState) {
        setStateModal(modalState);
    }

    function onOpenStateModal(state: ModalState) {
        setIsStateModalOpen(true);
        onSetStateModal(state);
    }

    function onCloseStateModal() {
        setIsStateModalOpen(false);
    }

    function onSetReviewer(reviewer: Reviewer) {
        setReviewer(reviewer);
    }

    function onSetClosedCampaignId(id: string) {
        setClosedCampaignId(id);
    }

    async function getCurrentCampaign() {
        try {
            onSetLoading(true);
            const response = await cerberusService.GetCurrentCampaign();

            if (response.data) {
                const activeCampaign = response.data as Campaign;
                setActiveCampaign(activeCampaign);
            }
        } catch (error) {
            setActiveCampaign(null);
        } finally {
            onSetLoading(false);
        }
    }

    async function getClosedCampaigns() {
        try {
            onSetLoading(true);
            const response = await cerberusService.GetClosedCampaigns();
            if (response.data) {
                const data = response.data as GetClosedCampaignsAPIResponse;
                setClosedCampaigns(data);
            }
        } catch (error) {
            setClosedCampaigns(initialData);
        } finally {
            onSetLoading(false);
        }
    }

    function onSyncCampaign(campaignUpdates: Partial<Campaign>) {
        setActiveCampaign((prevCampaign) => {
            if (!prevCampaign) {
                return null;
            }
            return {
                ...prevCampaign,
                ...campaignUpdates,
            };
        });
    }

    async function getReviewer() {
        try {
            onSetLoading(true);
            const response = await cerberusService.GetReviewer();
            if (response.data) {
                onSetReviewer(response.data);
                return;
            }
        } finally {
            onSetLoading(false);
        }
    }

    useEffect(() => {
        getReviewer();
        getCurrentCampaign();
        getClosedCampaigns();
        trackEvent({
            type: "PageView",
            contents: JSON.stringify({
                page: "User Access Reviews Page",
                params: { date: Date.now(), user: currentAccount },
            }),
        });
    }, []);

    if (isOwner) {
        cn += " owner";
    }

    return (
        <>
            {isLoading && <LoadingIndicator />}
            <div
                className={cn.toLocaleLowerCase()}
                data-testid={TestIds.UserAccessReviewsPage}
            >
                <Restricted toRoles={["Owners"]}>
                    <Authorized>
                        <SideBar
                            title="Dashboard"
                            listItems={[
                                {
                                    id: SideBarItems.MyReviews,
                                    label: "My Reviews",
                                    icon: (
                                        <FontAwesomeIcon icon={faListCheck} />
                                    ),
                                    onClick: () =>
                                        navigate(routePath.USER_ACCESS_REVIEWS),
                                },
                                {
                                    id: SideBarItems.CurrentCampaign,
                                    label: "Current Campaign",
                                    icon: <FontAwesomeIcon icon={faClock} />,
                                    onClick: () =>
                                        navigate(
                                            routePath.UAR_CAMPAIGNS_CURRENT
                                        ),
                                },
                                {
                                    id: SideBarItems.ClosedCampaign,
                                    label: "Closed Campaign",
                                    icon: <FontAwesomeIcon icon={faLock} />,
                                    onClick: () =>
                                        navigate(
                                            routePath.UAR_CAMPAIGNS_CLOSED
                                        ),
                                },
                            ]}
                            activeItem={routeLocationMap[location.pathname]}
                        />
                    </Authorized>
                </Restricted>
                <div className="outlet">
                    <Outlet
                        context={{
                            onSetLoading,
                            isLoading,
                            activeCampaign,
                            closedCampaigns,
                            closedCampaignId,
                            currentAccount,
                            reviewer,
                            isOwner,
                            isMobileScreen,
                            onSetReviewer,
                            onSetClosedCampaignId,
                            onOpenStateModal,
                            onCloseStateModal,
                            onSyncCampaign,
                        }}
                    />
                    <StateModal
                        {...stateModal}
                        isOpen={isStateModalOpen}
                        testId={TestIds.StateModal}
                    />
                </div>
            </div>
        </>
    );
}
