import React, { useState } from "react";
import {
    Campaign,
    CampaignStatus,
    DecisionStatus,
} from "@services/cerberus/model";
import { TestIds } from "@pages/user-access-reviews-page/test-ids";
import { ReviewCounts } from "../stats-and-table-view/components/review-counts";
import { Badge, Button } from "@cpchem/covalence-ui";
import { useNavigate } from "react-router";
import { useService } from "../../../../service-provider";
import { CerberusService, CerberusServiceKey } from "@services";
import { Accordion, StateModal } from "@components";
import { ModalState } from "@components/state-modal/constants";
import {
    CloseCampaignSuccessModalState,
    AreYouSureModalState,
    createCampaignDangerModalState,
} from "../current-campaign-view/components/create-campaign/constants";

import "./styles.scss";

interface CampaignDetailsProps {
    campaign: Campaign;
    testId?: string;
    decisionToFilterBy: DecisionStatus | null | undefined;
    reviewCounts: {
        inQueueReviewCount: number;
        unsureReviewCount: number;
        completeReviewCount: number;
    };
    onFilterByDecision: (decision: DecisionStatus | null) => void;
}

export function CampaignDetails({
    campaign,
    testId: incomingTestId,
    decisionToFilterBy,
    reviewCounts,
    onFilterByDecision,
}: CampaignDetailsProps) {
    const { startedBy, dueDate, startDate } = campaign;
    const testId = incomingTestId || TestIds.CampaignDetails;

    const [stateModalVisible, setStateModalVisible] = useState(false);
    const [modalState, setModalState] = useState<ModalState | null>(null);
    const navigate = useNavigate();
    const cerberusService = useService<CerberusService>(CerberusServiceKey);

    let isDisabled = true;

    function showCampaignCloseSuccess() {
        setModalState({
            ...CloseCampaignSuccessModalState,
            onClose() {
                setStateModalVisible(false);
                navigate(0);
            },
        });
    }

    async function handleCloseCampaign() {
        try {
            const response = await cerberusService.CloseCurrentCampaign();
            if (response.error === undefined) {
                showCampaignCloseSuccess();
                return;
            }
            showCampaignCloseError(response.error);
        } catch (error) {
            showCampaignCloseError(error);
        }
    }

    function OnCloseCampaign() {
        setStateModalVisible(true);
        setModalState({
            ...AreYouSureModalState,
            onClose: () => {
                setStateModalVisible(false);
            },
            onActionClick() {
                handleCloseCampaign();
            },
            actionButtonColor: "danger",
            actionButtonText: "Close Campaign",
        });
    }

    function showCampaignCloseError(error: unknown) {
        setModalState({
            ...createCampaignDangerModalState,
            contentText: `${error}`,

            onClose: () => {
                setStateModalVisible(false);
            },
        });
        setStateModalVisible(true);
    }

    const totalReviewCount =
        reviewCounts.inQueueReviewCount +
        reviewCounts.unsureReviewCount +
        reviewCounts.completeReviewCount;

    if (
        reviewCounts.completeReviewCount === totalReviewCount &&
        totalReviewCount > 0
    ) {
        isDisabled = false;
    }

    return (
        <div className="campaign-details" data-testid={testId}>
            <Accordion label="Campaign Details" defaultOpen>
                <div className="details">
                    <div className="content">
                        <Badge
                            className="badge"
                            text={CampaignStatus[0]}
                            variant="solid"
                            testId={TestIds.CampaignStatus}
                        />
                        <div className="description">
                            <p
                                className="start-date"
                                data-testid={TestIds.CampaignStartDate}
                            >
                                <strong>Start Date: </strong>
                                {new Date(startDate).toLocaleDateString()}
                            </p>
                            <p
                                className="due-date"
                                data-testid={TestIds.CampaignDueDate}
                            >
                                <strong>Due Date: </strong>
                                {new Date(dueDate).toLocaleDateString()}
                            </p>
                            <p
                                className="started-by"
                                data-testid={
                                    TestIds.CampaignStartedByDisplayName
                                }
                            >
                                <strong>Started By: </strong>
                                {startedBy.displayName}
                            </p>
                        </div>
                        <ReviewCounts
                            testId={TestIds.CampaignReviewCounts}
                            className="campaign-details-review-counts"
                            reviewCounts={reviewCounts}
                            decisionToFilterBy={decisionToFilterBy}
                            onFilterByDecision={onFilterByDecision}
                        />
                    </div>
                    <Button
                        variant="solid"
                        color="primary"
                        className="close-campaign-button"
                        text="Close Campaign"
                        isDisabled={isDisabled}
                        testId={TestIds.CloseCampaignButton}
                        onClick={OnCloseCampaign}
                    />
                    {stateModalVisible && modalState && (
                        <StateModal
                            {...modalState}
                            isOpen={stateModalVisible}
                            testId={TestIds.CloseCurrentCampaignModal}
                        />
                    )}
                </div>
            </Accordion>
        </div>
    );
}
