export enum CampaignStatus {
    "In Progress" = 0,
    "Completed" = 1,
}

export type Campaign = {
    id: string;
    closedDate: string | null;
    startedBy: {
        displayName: string;
        samlId: string | null;
    };
    dueDate: string;
    closedBy: {
        displayName: string;
        samlId: string;
    } | null;
    gitHubOrganizationId: string;
    startDate: string;
    status: CampaignStatus;
    completeReviewCount: number;
    inQueueReviewCount: number;
    totalReviewCount: number;
    unsureReviewCount: number;
};

export type ClosedCampaign = {
    id: string;
    startDate: string;
    closedDate: string;
    closedBy: string;
    download: string;
};

export type ClosedCampaignDetail = {
    repositoryDescription: string;
    repositoryName: string;
    reviewedBy: string;
    reviewedDate: string | null;
};

export enum DecisionStatus {
    "Unsure" = 0,
    "Completed" = 1,
}

export enum Role {
    Read = "Read",
    Write = "Write",
    Admin = "Admin",
    Maintain = "Maintain",
    Triage = "Triage",
}

export type Comment = {
    addedBy: {
        displayName: string;
        userName: string | null;
        samlId: string;
        avatarUrl: string | null;
    };
    addedDate: string;
    comment: string;
};

export type Review = {
    comments: Comment[] | null;
    commentsCount: number;
    decisionStatus: DecisionStatus | null;
    id: string;
    repoCollaborators: RepoCollaborator[];
    repositoryDescription: string;
    repositoryName: string;
    reviewedBy: {
        displayName: string;
        userName: string | null;
        samlId: string;
        avatarUrl: string | null;
    } | null;
    isBeingUpdated: boolean;
};

export type TeamMember = {
    displayName: string;
    userName: string | null;
    samlId: string;
    avatarUrl: string | null;
};

export type RepoCollaborator = {
    collaboratorName: string;
    collaboratorUsername: string;
    avatarUrl: string | null;
    role: Role;
    teamMembers: TeamMember[] | null;
};

export type Reviewer = {
    avatarUrl: string | null;
};
