import React, { useState } from "react";
import { DevSpotModal } from "@components/modal";
import { DateSelector } from "@components";
import { tomorrowDate, twoWeeksFromNowDate } from "./constants";
import { getCurrentDate } from "@utilities/date";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/pro-light-svg-icons";
import { FileInput } from "@components/file-input";
import { log, LogLevel } from "@cpchem/logging";

import "./styles.scss";
import { Button, Tooltip } from "@cpchem/covalence-ui";
import { CreateCampaignViewTestIds } from "@pages/user-access-reviews-page/test-ids";

interface CreateCampaignModalProps {
    testid?: string;
    isOpen: boolean;
    onStartCampaign: (dueDate: Date, ADUserFile: File) => void;
    onClose: () => void;
}

export default function CreateCampaignModal({
    testid,
    isOpen,
    onStartCampaign,
    onClose,
}: CreateCampaignModalProps): JSX.Element {
    const [campaignStartDate] = useState(getCurrentDate());
    const [campaignUserDueDate, setCampaignUserDueDate] = useState<
        Date | undefined
    >(twoWeeksFromNowDate);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [isValid, setIsValid] = useState<boolean | undefined>(undefined);

    function handleUpdateDueDate(dueDate: Date | undefined) {
        setCampaignUserDueDate(dueDate);
    }

    function onRequestStartCampaign() {
        if (campaignUserDueDate && selectedFile) {
            onStartCampaign(campaignUserDueDate, selectedFile);
        } else {
            if (!campaignUserDueDate) {
                log(
                    "Attempt to start campaign without an EndDate",
                    LogLevel.WARN
                );
            } else {
                log("Attempt to start campaign without a File", LogLevel.WARN);
            }
        }
    }

    function handleOnFileChanged(file: File | null) {
        if (file?.type === "application/json") {
            setSelectedFile(file);
            setIsValid(true);
        } else {
            setIsValid(false);
        }
    }
    function handleOnCloseRequest() {
        onClose();
        setSelectedFile(null);
        setIsValid(undefined);
    }

    const isStartCampaignButtonDisabled =
        !campaignUserDueDate || !selectedFile || !isValid;

    return (
        <DevSpotModal
            title={"Create New Campaign"}
            isOpen={isOpen}
            onRequestClose={handleOnCloseRequest}
            className={"create-campaign-modal"}
            testId={testid}
            header={
                <div className="modal-description">
                    Starting a campaign will create a list of repositories that
                    can be attested. Please select a due date then click start
                    to create a new campaign.
                </div>
            }
            footer={
                <div className="devspot-modal-submit-buttons">
                    <Button
                        text="Cancel"
                        variant="outline"
                        color="primary"
                        className="cancel-button"
                        onClick={handleOnCloseRequest}
                        testId={
                            CreateCampaignViewTestIds.CancelCreateCampaignButton
                        }
                    />
                    <Button
                        text="Start Campaign"
                        color="primary"
                        className="start-campaign-button"
                        onClick={onRequestStartCampaign}
                        isDisabled={isStartCampaignButtonDisabled}
                        testId={CreateCampaignViewTestIds.StartCampaignButton}
                    />
                </div>
            }
        >
            <div className="modal-campaign-information">
                <div className="campaign-date-information">
                    <span className="campaign-start">Start Date</span>
                    <br />
                    <p
                        className="campaign-start-date"
                        data-testid={
                            CreateCampaignViewTestIds.CreateCampaignStartDate
                        }
                    >
                        {campaignStartDate.toLocaleDateString()}
                    </p>
                </div>
                <div className="date-selector">
                    <label className="date-input-label" htmlFor="input">
                        Please select a due date
                    </label>
                    <DateSelector
                        testId={
                            CreateCampaignViewTestIds.CreateCampaignDueDateDateSelector
                        }
                        onChange={handleUpdateDueDate}
                        defaultDate={campaignUserDueDate}
                        minDate={tomorrowDate}
                    />
                </div>
                <div className="file-upload">
                    <div className="label-container">
                        <label
                            className="label"
                            htmlFor="input"
                            data-testid={
                                CreateCampaignViewTestIds.FileInputLabel
                            }
                        >
                            Upload Membership Export
                        </label>
                        <Tooltip
                            variant="alt"
                            side="top"
                            content="The File can be acquired from a GitHub Org Admin."
                            data-testid={
                                CreateCampaignViewTestIds.FileInputLabelTooltip
                            }
                        >
                            <FontAwesomeIcon
                                className="icon"
                                icon={faCircleInfo}
                                data-testid={
                                    CreateCampaignViewTestIds.FileInputLabelIcon
                                }
                            />
                        </Tooltip>
                    </div>
                    <FileInput
                        testId={CreateCampaignViewTestIds.FileInput}
                        acceptedFiles={[".json"]}
                        onFileChanged={handleOnFileChanged}
                        isValid={isValid}
                    />
                </div>
                {isValid === false && (
                    <p
                        className="wrong-file-message"
                        data-testId={
                            CreateCampaignViewTestIds.FileInputWrongFileMessage
                        }
                    >
                        The file selected is not a json file.
                    </p>
                )}
            </div>
        </DevSpotModal>
    );
}
