import { getDecisionStatus } from "@pages/user-access-reviews-page/constants";
import { Review } from "@services/cerberus/model";
import { SortingFnOption } from "@tanstack/react-table";

export const decisionStatusSortFn: SortingFnOption<Review> | undefined = (
    a,
    b
) => {
    const { sortIndex: aSortIndex } = getDecisionStatus(
        a.getValue("decisionStatus")
    );
    const { sortIndex: bSortIndex } = getDecisionStatus(
        b.getValue("decisionStatus")
    );
    return aSortIndex - bSortIndex;
};

export const commentsCountSortFn: SortingFnOption<Review> | undefined = (
    a,
    b
) => {
    const aCommentsCount = a.getValue("commentsCount") as number;
    const bCommentsCount = b.getValue("commentsCount") as number;
    return bCommentsCount - aCommentsCount;
};

export const repositoryNameSortFn: SortingFnOption<Review> | undefined = (
    a,
    b
) => {
    const aRepositoryName = a.getValue("repositoryName") as string;
    const bRepositoryName = b.getValue("repositoryName") as string;
    return aRepositoryName.localeCompare(bRepositoryName);
};
