import { LogLevel, log } from "@cpchem/logging";

export function handle409CreateCampaignResponse() {
    log(
        `There is already an active campaign. Please wait until the current campaign is complete before creating a new one..`,
        LogLevel.ERROR
    );
    return {
        error: "There is already an active campaign. Please wait until the current campaign is complete before creating a new one.",
    };
}

export function handle409InactiveCampaignResponse() {
    log(
        `The campaign is no longer active. Please refresh the page to get the latest data.`,
        LogLevel.ERROR
    );
    return {
        error: "The campaign is no longer active. Please refresh the page to get the latest data.",
    };
}
