import { Ghost } from "@components/ghost";
import React from "react";

import "./styles.scss";

interface GhostReviewDetailsProps {
    testId?: string;
}

export function GhostReviewDetails({ testId }: GhostReviewDetailsProps) {
    return (
        <div className="ghost-body" data-testid={testId}>
            <div className="header">
                <Ghost variant="circular" height={32} width={32} />
                <Ghost height={32} width={300} />
            </div>

            <div className="body">
                <Ghost height={10} />
                <Ghost height={50} />
                <Ghost height={50} />
                <Ghost height={150} />
                <Ghost height={50} />
                <Ghost height={50} />
            </div>

            <div className="footer">
                <Ghost height={10} />
                <Ghost height={50} />
                <Ghost height={50} />
                <Ghost height={50} />
                <Ghost height={50} />
                <Ghost height={150} />
            </div>
        </div>
    );
}
