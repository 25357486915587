import { ModalSeverity, ModalState } from "@components/state-modal/constants";

export const createCampaignSuccessModalState: ModalState = {
    severity: ModalSeverity.Success,
    title: "Success",
    subText: "You have created a campaign",
    contentHeader: "Thank You!",
    contentText:
        "You have successfully created a new user access review campaign.",
};
export const CloseCampaignSuccessModalState: ModalState = {
    severity: ModalSeverity.Success,
    title: "Success",
    subText: "You have closed the campaign",
    contentHeader: "Thank You!",
    contentText: "The campaign has now concluded.",
};
export const AreYouSureModalState: ModalState = {
    severity: ModalSeverity.Danger,
    title: "Are you Sure?",
    subText: "Do you want to close the campaign",
    contentHeader: "Have you checked?",
    contentText:
        "Please ensure to conclude the campaign by effectively closing all remaining tasks and wrapping up any loose ends. This will help us achieve our goals successfully and maintain a professional and organized approach throughout the project.",
    actionButtonColor: "danger",
};
export const createCampaignDangerModalState: ModalState = {
    severity: ModalSeverity.Danger,
    title: "Error",
    subText: "There was an error creating a campaign",
    contentHeader: "Error!",
};

export const errorGettingReviewsModalState: ModalState = {
    severity: ModalSeverity.Caution,
    title: "Warning",
    subText: "Failed to retrieve data",
    contentText: "Return to app",
};
