import React, { useEffect, useState } from "react";
import { DevSpotModal } from "@components/modal";
import { CommentsInput } from "@pages/user-access-reviews-page/components/review-details/components/comments-input";
import { Button } from "@cpchem/covalence-ui";
import { faTriangleExclamation } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./styles.scss";

interface DecisionCommentModalProps {
    isOpen: boolean;
    onAddComment: (comment: string) => void;
    onClose: () => void;
    testId?: string;
}

export default function DecisionCommentModal({
    isOpen,
    onClose,
    onAddComment,
    testId,
}: DecisionCommentModalProps): JSX.Element {
    const [comment, setComment] = useState<string>("");

    function handleOnClose() {
        onClose();
    }

    function handleAddComment() {
        onAddComment(comment);
    }

    const isAddCommentButtonDisabled = !comment;

    // This is a clean up method that will be called when the component unmounts
    useEffect(
        () => {
            return () => {
                setComment("");
            };
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    return (
        <DevSpotModal
            isOpen={isOpen}
            onRequestClose={handleOnClose}
            className={"decision-comment-modal"}
            testId={testId}
            header={
                <div className="decision-comment-header">
                    <div className={`icon-background caution`}>
                        <FontAwesomeIcon
                            icon={faTriangleExclamation}
                            className="icon"
                        />
                    </div>
                    <p data-testid={`${testId}-title`} className="title">
                        Add Comment
                    </p>
                    <p className="subtext" data-testid={`${testId}-subtext`}>
                        Unsure decisions require a comment and canceling will
                        undo this decision.
                    </p>
                </div>
            }
            footer={
                <div className="decision-comment-footer">
                    <Button
                        text="Cancel"
                        variant="outline"
                        color="primary"
                        className="cancel-button"
                        onClick={handleOnClose}
                        testId={`${testId}-cancel-button`}
                    />
                    <Button
                        text="Add Comment"
                        color="primary"
                        className="add-comment-button"
                        onClick={handleAddComment}
                        isDisabled={isAddCommentButtonDisabled}
                        testId={`${testId}-add-comment-button`}
                    />
                </div>
            }
        >
            <CommentsInput
                hideLabel
                comment={comment}
                onHandleChange={setComment}
                testId={`${testId}-comments-input`}
            />
        </DevSpotModal>
    );
}
