export const UserAccessReviewsTestIds = {
    UserAccessReviewsPage: "user-access-reviews-page",
};

export const CreateCampaignViewTestIds = {
    CreateCampaignView: "create-campaign-view",
    CreateCampaignButton: "create-campaign-button",
    CreateCampaignModal: "create-campaign-modal",
    CreateCampaignDueDateDateSelector: "create-campaign-due-date-date-selector",
    CreateCampaignDueDateInput: "create-campaign-due-date-date-selector-input",
    CreateCampaignStartDate: "create-campaign-start-date",
    StartCampaignButton: "start-campaign-button",
    CancelCreateCampaignButton: "cancel-create-campaign-button",
    StateModal: "state-modal",
    StateModalTitle: "state-modal-title",
    StateModalDescription: "state-modal-description",
    StateModalCloseButton: "state-modal-close-button",
    StateModalActionButton: "state-modal-action-button",
    StateModalSubText: "state-modal-subtext",
    FileInput: "file-input",
    FileInputLabel: "file-input-label",
    FileInputLabelIcon: "file-input-label-icon",
    FileInputLabelTooltip: "file-input-label-tooltip",
    FileInputWrongFileMessage: "wrong-file-message",
};

export const CurrentCampaignViewTestIds = {
    CurrentCampaignView: "current-campaign-view",
};

export const MyReviewsTestIds = {
    MyReviewsView: "my-reviews-view",
};

export const ReviewsTableTestIds = {
    ReviewsView: "reviews",
    ReviewsHeader: "reviews-table-header",
    ReviewsTitle: "reviews-table-title",
    ReviewsTable: "reviews-table",
    ReviewsTableContainer: "reviews-table-container",
    ReviewsTableHead: "reviews-table-head",
    ReviewsTableHeadRow: "reviews-table-head-row",
    ReviewsTableBody: "reviews-table-body",
    ReviewsTableRow: "reviews-table-row",
    ReviewsTableRowCell: "reviews-table-row-cell",
    NoReviewsAlert: "no-reviews-alert",
};

export const StatsAndReviewsTableViewTestIds = {
    StatsAndReviewsTableView: "stats-and-reviews-table-view",
    StatsAndReviewsTableViewHeader: "stats-and-reviews-table-view-header",
    StatsAndReviewsTableViewTitle: "stats-and-reviews-table-view-title",
    StatsAndReviewsTableViewWelcomeMessage:
        "stats-and-reviews-table-view-welcome-message",
    StatsAndReviewsTableViewReviewCounts:
        "stats-and-reviews-table-view-review-counts",
    DecisionCommentModal: "decision-comment-modal",
};

export const CampaignDetailsTestIds = {
    CampaignDetails: "campaign-details",
    CampaignStartDate: "campaign-start-date",
    CampaignDueDate: "campaign-due-date",
    CampaignStartedByDisplayName: "campaign-started-by-display-name",
    CloseCampaignButton: "close-campaign-button",
    CampaignStatus: "campaign-status",
    CampaignReviewCounts: "campaign-review-counts",
    CloseCurrentCampaignModal: "close-current-campaign-modal",
};

export const ReviewCountsTestIds = {
    ReviewCounts: "review-counts",
    ReviewCountPill: "review-count-pill",
};

const ReviewDetailsTestIds = {
    ReviewDetails: "review-details",
    ReviewDetailsView: "review-details-view",
    ReviewDetailsHeader: "review-details-header",
    ReviewDetailsRepoTitle: "review-details-title",
    ReviewDetailsRepoDescription: "review-details-description",
    ReviewDetailsMobileCloseButton: "review-details-mobile-close-button",
    ReviewDetailsLink: "review-details-link",
    ReviewDetailsReviewer: "review-details-reviewer",
    ReviewDetailsReviewerDisplayName: "review-details-reviewer-display-name",
    ReviewDetailsReviewerUserName: "review-details-reviewer-username",
    ReviewDetailsDecisionStatusSelector:
        "review-details-decision-status-selector",
    ReviewDetailsDecisionStatusSelectorOption:
        "review-details-decision-status-selector-option",
    ReviewDetailsAccessRoles: "review-details-access-roles",
    ReviewDetailsAddAccessRoleAnchor: "review-details-add-access-role-button",
    ReviewDetailsRefreshAccessRolesButton:
        "review-details-refresh-access-roles-button",
    ReviewDetailsAccessRole: "review-details-access-role",
    ReviewDetailsAddCommentButton: "review-details-add-comment-button",
    ReviewDetailsMobileBackButton: "review-details-mobile-back-button",
    ReviewDetailsCancelAddCommentButton:
        "review-details-cancel-add-comment-button",
    ReviewDetailsGhost: "review-details-ghost",
    ReviewDetailsToggleRolesButton: "review-details-toggle-roles-button",
    ReviewDetailsComments: "review-details-comments",
    ReviewDetailsComment: "review-details-comment",
    ReviewDetailsCommentsInput: "review-details-comments-input",
    ReviewDetailsRefreshView: "review-details-refresh-view",
    RefreshViewHeader: "refresh-view-header",
    RefreshViewBody: "refresh-view-body",
    RefreshViewMobileCloseButton: "refresh-view-mobile-close-button",
    RefreshViewRefreshButton: "refresh-view-refresh-button",
    RefreshViewTitle: "refresh-view-title",
    RefreshViewSubText: "refresh-view-subtext",
    RefreshViewContent: "refresh-view-content",
};

export const ClosedCampaignTestIds = {
    ClosedCampaignsView: "closed-campaigns-view",
    ClosedCampaignsTable: "closed-campaigns-table",
    DownloadButton: "download-closed-campaign-button",
};

export const ClosedCampaignDetailTestIds = {
    ClosedCampaignDetailView: "closed-campaign-detail-view",
    // CampaignId: "campaign-id",
    // CampaignRepoName: "campaign-repo-name",
    // CampaignRepoDescription: "campaign-repo-description",
    // CampaignReviewedDate: "campaign-reviewed-date",
    // CampaignReviewStatus: "campaign-review-status",
    // CampaignReviewedBy: "campaign-reviewed-by",
    // CampaignClosedDate: "campaign-closed-date",
    // CampaignClosedBy: "campaign-closed-by",
};

const CellRenders = {
    NoDataRender: "no-data-render",
};

export const TestIds = {
    ...UserAccessReviewsTestIds,
    ...CurrentCampaignViewTestIds,
    ...CreateCampaignViewTestIds,
    ...MyReviewsTestIds,
    ...ReviewsTableTestIds,
    ...StatsAndReviewsTableViewTestIds,
    ...CampaignDetailsTestIds,
    ...ReviewCountsTestIds,
    ...ReviewDetailsTestIds,
    ...ClosedCampaignTestIds,
    ...ClosedCampaignDetailTestIds,
    CellRenders,
};
