import React from "react";
import { TestIds } from "@pages/user-access-reviews-page/test-ids";
import { Accordion } from "@components";
import { faPlus, faMinus } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { capitalizeFirstLetter } from "@utilities/text";
import { Collaborator } from "../../../collaborator";
import { RepoCollaborator, Role as RoleType } from "@services/cerberus/model";

interface AccessRoleProps {
    role: RoleType;
    description: string;
    isExpanded?: boolean;
    testId?: string;
    repoCollaborators: RepoCollaborator[];
}

export function AccessRole({
    role,
    description,
    testId,
    isExpanded,
    repoCollaborators,
}: AccessRoleProps) {
    return (
        <Accordion
            label={capitalizeFirstLetter(role)}
            className="role"
            isClosedIcon={<FontAwesomeIcon icon={faPlus} className="icon" />}
            isOpenIcon={<FontAwesomeIcon icon={faMinus} className="icon" />}
            testId={`${testId}-${TestIds.ReviewDetailsAccessRole}-${role}`}
            key={role}
            defaultOpen={isExpanded}
        >
            <div
                className="description"
                data-testid={`${testId}-${TestIds.ReviewDetailsAccessRole}-${role}-description`}
            >
                {description}
            </div>

            {repoCollaborators && (
                <div
                    className="collaborators"
                    data-testid={`${testId}-${TestIds.ReviewDetailsAccessRole}-${role}-collaborators`}
                >
                    {repoCollaborators
                        .filter((collaborator) => collaborator.role === role)
                        .map((collaborator) => (
                            <Collaborator
                                key={`${collaborator.collaboratorName}-${collaborator.collaboratorUsername}`}
                                collaboratorName={collaborator.collaboratorName}
                                collaboratorUsername={
                                    collaborator.collaboratorUsername
                                }
                                teamMembers={collaborator.teamMembers}
                                testId={`${testId}-${TestIds.ReviewDetailsAccessRole}-${role}-collaborator`}
                                avatarUrl={collaborator.avatarUrl}
                            />
                        ))}
                </div>
            )}
        </Accordion>
    );
}
