import React, { useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus } from "@fortawesome/pro-solid-svg-icons";

import "./styles.scss";

interface FileInputProps {
    testId?: string;
    className?: string;
    acceptedFiles?: string[];
    isValid?: boolean;
    onFileChanged: (file: File | null) => void;
}

export function FileInput({
    testId,
    className,
    acceptedFiles,
    isValid,
    onFileChanged,
}: FileInputProps): JSX.Element {
    const [file, setFile] = useState<File | null>(null);

    const fileInput = useRef<HTMLInputElement>(null);

    let cn = "file-input";

    let infoCn = "info-container";

    if (className) {
        cn += ` ${className}`;
    }

    if (isValid === true) {
        infoCn += " is-valid";
    } else if (isValid === false) {
        infoCn += " is-invalid";
    }

    function HandleFileInput(e: React.ChangeEvent<HTMLInputElement>) {
        if (e.target.files) {
            const selectedFile = e.target.files.item(0);
            setFile(selectedFile);
            onFileChanged(selectedFile);
        }
    }

    function onFocusInput() {
        fileInput.current?.click();
    }

    const infoContainerTestId = testId ? `${testId}-info-container` : undefined;
    const fileNameTestId = testId ? `${testId}-file-name` : undefined;
    const placeholderTestId = testId ? `${testId}-place-holder` : undefined;
    const inputTestId = testId ? `${testId}-input` : undefined;

    return (
        <div data-testid={testId} className={cn}>
            <div
                className={infoCn}
                onClick={onFocusInput}
                data-testid={infoContainerTestId}
                role="button"
                tabIndex={0}
                onKeyDown={onFocusInput}
            >
                {file ? (
                    <p className="file-name" data-testid={fileNameTestId}>
                        {file?.name}
                    </p>
                ) : (
                    <p className="place-holder" data-testid={placeholderTestId}>
                        Browse files
                    </p>
                )}
                <FontAwesomeIcon
                    className="icon"
                    icon={faCirclePlus}
                    aria-label="Add File"
                />
            </div>
            <input
                data-testid={inputTestId}
                className="input"
                type="file"
                ref={fileInput}
                accept={acceptedFiles ? acceptedFiles.join(",") : ""}
                onChange={HandleFileInput}
            />
        </div>
    );
}
