export function validateSelectedDate(
    selectedDate: Date | undefined,
    minDate: Date | undefined,
    maxDate: Date | undefined
): boolean {
    if (!selectedDate) {
        return false;
    }

    if (minDate && selectedDate.valueOf() < minDate.valueOf()) {
        return false;
    }

    if (maxDate && selectedDate.valueOf() > maxDate.valueOf()) {
        return false;
    }

    return true;
}
