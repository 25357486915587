import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faTimes,
    faTriangleExclamation,
} from "@fortawesome/pro-light-svg-icons";
import { TestIds } from "../../../../test-ids";

import "./styles.scss";
import { Button } from "@cpchem/covalence-ui";

interface RefreshViewProps {
    className?: string;
    testId?: string;
    onClose?: () => void;
    onRefresh?: () => void;
}

function RefreshView({
    className,
    testId,
    onClose,
    onRefresh,
}: RefreshViewProps) {
    let cn = "refresh-view";
    if (className) {
        cn += ` ${className}`;
    }
    return (
        <div
            className={cn}
            data-testid={`${testId}-${TestIds.ReviewDetailsRefreshView}`}
        >
            <div
                className="header"
                data-testid={`${testId}-${TestIds.RefreshViewHeader}`}
            >
                <FontAwesomeIcon
                    icon={faTimes}
                    className="close-icon"
                    onClick={onClose}
                    data-testid={`${testId}-${TestIds.RefreshViewMobileCloseButton}`}
                />
            </div>
            <div
                className="body"
                data-testid={`${testId}-${TestIds.RefreshViewBody}`}
            >
                <div className={`icon-background caution`}>
                    <FontAwesomeIcon
                        icon={faTriangleExclamation}
                        className="icon"
                    />
                </div>
                <p
                    data-testid={`${testId}-${TestIds.RefreshViewTitle}`}
                    className="title"
                >
                    Please Wait
                </p>
                <p
                    className="subtext"
                    data-testid={`${testId}-${TestIds.RefreshViewSubText}`}
                >
                    This data is currently being updated
                </p>
                <div
                    className="content"
                    data-testid={`${testId}-${TestIds.RefreshViewContent}`}
                >
                    <p>
                        This may take up to 5 minutes, please come back later
                        and refresh.
                    </p>
                </div>
            </div>

            <div className="actions">
                <Button
                    className="button"
                    onClick={onRefresh}
                    data-testid={`${testId}-${TestIds.RefreshViewRefreshButton}`}
                    color="primary"
                    text={"Refresh"}
                    size="large"
                    variant="solid"
                />
            </div>
        </div>
    );
}

export default RefreshView;
