// Utility function that returns a random item from an array
export function getRandomItemFromArray<T>(items: T[]): T {
    return items[Math.floor(Math.random() * items.length)];
}

export const colorsForNames = [
    "blue",
    "orange",
    "green",
    "teal",
    "red",
    "purple",
    "magenta",
];

export const getColorFromName = (name: string) => {
    const randomIndexFromName = name.charCodeAt(0) % colorsForNames.length;
    return colorsForNames[randomIndexFromName];
};
