import React, { memo, useMemo, useState } from "react";
import {
    getCoreRowModel,
    useReactTable,
    getSortedRowModel,
    SortingState,
    RowSelectionState,
    OnChangeFn,
} from "@tanstack/react-table";
import { Review } from "@services/cerberus/model";
import { TestIds } from "@pages/user-access-reviews-page/test-ids";
import { desktopColumns, mobileColumns } from "./columns";
import TableRow from "./components/table-row";
import TableHead from "./components/table-head";

import "./styles.scss";

interface ReviewsTableProps {
    data: Review[];
    selectedRow: RowSelectionState;
    onRowSelectionChange: OnChangeFn<RowSelectionState>;
    testId?: string;
    isMobile?: boolean;
}

const ReviewsTable = ({
    data,
    selectedRow,
    onRowSelectionChange,
    testId: incomingTestId,
    isMobile,
}: ReviewsTableProps) => {
    const [sorting, setSorting] = useState<SortingState>([]);

    const tableColumns = useMemo(() => {
        return isMobile ? mobileColumns : desktopColumns;
    }, [isMobile]);

    const table = useReactTable({
        data,
        columns: tableColumns,
        state: {
            sorting,
            rowSelection: selectedRow,
        },
        enableRowSelection: true,
        enableMultiRowSelection: false,
        onRowSelectionChange: onRowSelectionChange,
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });

    const testId = incomingTestId || TestIds.ReviewsView;

    return (
        <div className="reviews" data-testid={testId}>
            <div
                className="header"
                data-testid={`${testId}-${TestIds.ReviewsHeader}`}
            >
                <p
                    className="title"
                    data-testid={`${testId}-${TestIds.ReviewsTitle}`}
                >
                    Reviews
                </p>
            </div>
            <div
                className="table"
                data-testid={`${testId}-${TestIds.ReviewsTableContainer}`}
            >
                <table
                    style={{ tableLayout: "fixed", width: "100%" }}
                    data-testid={`${testId}-${TestIds.ReviewsTable}`}
                >
                    <thead
                        data-testid={`${testId}-${TestIds.ReviewsTableHead}`}
                    >
                        {table.getHeaderGroups().map((headerGroup) => (
                            <TableHead
                                headerGroup={headerGroup}
                                testId={testId}
                                key={headerGroup.id}
                            />
                        ))}
                    </thead>
                    <tbody
                        data-testid={`${testId}-${TestIds.ReviewsTableBody}`}
                    >
                        {table.getRowModel().rows.map((row) => (
                            <TableRow
                                key={row.id}
                                isSelected={row.getIsSelected()}
                                row={row}
                                testId={testId}
                            />
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default memo(ReviewsTable);
