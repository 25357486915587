import { ApiResponse, ApiResponseWith404 } from "../api-response";
import {
    Campaign,
    Review,
    Reviewer,
    ClosedCampaign,
    ClosedCampaignDetail,
} from "./model";

export const ServiceKey = "CerberusService";

export interface CerberusService {
    /** Get Campaigns */
    GetCurrentCampaign: () => Promise<
        ApiResponse<GetCurrentCampaignAPIResponse>
    >;

    GetClosedCampaigns: () => Promise<
        ApiResponse<GetClosedCampaignsAPIResponse>
    >;

    GetClosedCampaignDetail: (
        id: string
    ) => Promise<ApiResponse<GetClosedCampaignDetailAPIResponse>>;

    /** Create a Campaign */
    CreateCampaign: ({
        dueDate,
        file,
    }: CreateCampaignAPIRequest) => Promise<
        ApiResponse<CreateCampaignAPIResponse>
    >;

    /** Closes Current Campaign */
    CloseCurrentCampaign: () => Promise<ApiResponse<undefined>>;

    GetReviews({
        campaignId,
        showAssigned,
    }: GetReviewsAPIRequest): Promise<ApiResponse<GetReviewsAPIResponse>>;

    /** Get Review By Id */
    GetReviewById({
        campaignId,
        reviewId,
    }: GetReviewByIdAPIRequest): Promise<
        ApiResponseWith404<GetReviewByIdAPIResponse>
    >;

    /** Update Review */
    UpdateReview({
        campaignId,
        reviewId,
        reviewUpdatePayload,
    }: UpdateReviewAPIRequest): Promise<ApiResponse<UpdateReviewAPIResponse>>;

    /** Refresh Review */
    RefreshReview({
        campaignId,
        reviewId,
    }: RefreshReviewAPIRequest): Promise<ApiResponse<RefreshReviewAPIResponse>>;

    /** Add Comment To Review */
    AddCommentToReview({
        campaignId,
        reviewId,
        comment,
    }: AddCommentToReviewAPIRequest): Promise<
        ApiResponse<AddCommentToReviewAPIResponse>
    >;

    /** Get Info For Current Reviewer */
    GetReviewer(): Promise<ApiResponse<GetReviewerAPIResponse>>;
}

export type GetCurrentCampaignAPIResponse = Campaign;

export type CloseCurrentCampaign = undefined;

export type GetCampaignByIdAPIResponse = Campaign;

export type GetReviewsAPIRequest = {
    campaignId: string;
    showAssigned: boolean;
};

export type GetReviewsAPIResponse = {
    reviews: Review[];
    completeReviewCount: number;
    inQueueReviewCount: number;
    unsureReviewCount: number;
};

export type CreateCampaignAPIResponse = Record<string, never>;

export type CreateCampaignAPIRequest = {
    dueDate: Date;
    file: File;
};

export type GetReviewByIdAPIResponse = Review;

export type UpdateReviewAPIRequest = {
    campaignId: string;
    reviewId: string;
    reviewUpdatePayload: Partial<Review>;
};

export type UpdateReviewAPIResponse = Record<string, never>;

export type RefreshReviewAPIRequest = {
    campaignId: string;
    reviewId: string;
};

export type RefreshReviewAPIResponse = Record<string, never>;

export type AddCommentToReviewAPIRequest = {
    campaignId: string;
    reviewId: string;
    comment: string;
};

export type AddCommentToReviewAPIResponse = Record<string, never>;

export type GetReviewByIdAPIRequest = {
    campaignId: string;
    reviewId: string;
};

export type CloseCurrentCampaignAPIResponse = undefined;

export type GetReviewerAPIResponse = Reviewer;

export type GetClosedCampaignsAPIResponse = ClosedCampaign[];

export type GetClosedCampaignDetailAPIRequest = {
    id: string;
};

export type GetClosedCampaignDetailAPIResponse = ClosedCampaignDetail[];
