/* eslint-disable no-empty-pattern */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-key */
import { TestIds } from "@pages/user-access-reviews-page/test-ids";
import { useUARContext } from "@pages/user-access-reviews-page/context";
import { ClosedCampaign } from "@services/cerberus/model";
import React, { useMemo } from "react";
import { Column, useTable } from "react-table";
import { CerberusService, CerberusServiceKey } from "@services";
import { useService } from "../../../../../../service-provider";
import {
    GetClosedCampaignDetailAPIResponse,
    GetClosedCampaignsAPIResponse,
} from "@services/cerberus/interface";
import "./styles.scss";
import { errorGettingCampaignDetailsModalState } from "@pages/user-access-reviews-page/constants";

interface ClosedCampaignTableProps {
    closedCampaignsData: GetClosedCampaignsAPIResponse;
}

export default function ClosedCampaignsTable({
    closedCampaignsData,
}: ClosedCampaignTableProps): JSX.Element {
    const cerberusService = useService<CerberusService>(CerberusServiceKey);
    const { onOpenStateModal, onCloseStateModal } = useUARContext();

    async function fetchCampaignDetailAndDownload(id: string) {
        try {
            const response = await cerberusService.GetClosedCampaignDetail(id);
            if (response.data) {
                const csvData =
                    response.data as GetClosedCampaignDetailAPIResponse;
                const csvLink = document.createElement("a");
                const csvContent = `data:text/csv;charset=utf-8,${encodeURIComponent(
                    generateCSVContent(csvData)
                )}`;
                csvLink.setAttribute("href", csvContent);
                csvLink.setAttribute("download", `campaign_${id}_report.csv`);
                document.body.appendChild(csvLink);
                csvLink.click();
                document.body.removeChild(csvLink);
            }
        } catch (error) {
            onOpenStateModal({
                ...errorGettingCampaignDetailsModalState,
                contentText: `${error}`,
                onClose: onCloseStateModal,
            });
        }
    }

    const generateCSVContent = (
        data: GetClosedCampaignDetailAPIResponse
    ): string => {
        const headers = `${Object.keys(data[0]).join(",")}\n`;
        const rows = data
            .map((row) =>
                Object.values(row)
                    .map((value) => `"${String(value).replace(/"/g, '""')}"`)
                    .join(",")
            )
            .join("\n");
        return headers + rows;
    };

    const columns: Column<ClosedCampaign>[] = useMemo(
        () => [
            {
                Header: "Campaign ID",
                accessor: "id",
            },
            {
                Header: "Campaign Start Date",
                accessor: "startDate",
            },
            {
                Header: "Campaign Closed Date",
                accessor: "closedDate",
            },
            {
                Header: "Campaign Closed By",
                accessor: "closedBy",
            },
            {
                Header: "Campaign Details",
                accessor: "download",
                Cell: ({ row }) => (
                    <button
                        onClick={() =>
                            fetchCampaignDetailAndDownload(row.original.id)
                        }
                        className="downloadbtn"
                        data-testid={`${TestIds.DownloadButton}-${row.id}`}
                    >
                        Download Report
                    </button>
                ),
            },
        ],
        []
    );

    const tableInstance = useTable({ columns, data: closedCampaignsData });
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
        tableInstance;

    return (
        <table {...getTableProps()} data-testid={TestIds.ClosedCampaignsTable}>
            <thead>
                {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                            <th {...column.getHeaderProps()}>
                                {column.render("Header")}
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                    prepareRow(row);
                    return (
                        <tr
                            {...row.getRowProps()}
                            style={{ cursor: "pointer" }}
                        >
                            {row.cells.map((cell) => (
                                <td {...cell.getCellProps()}>
                                    {cell.render("Cell")}
                                </td>
                            ))}
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
}
