import React from "react";

export function AppIcon(): JSX.Element {
    return (
        <svg
            width="32"
            height="25"
            viewBox="0 0 32 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M18.1242 0.625L17.6694 2.10565L11.4758 22.2347L11.021 23.7153L13.9823 24.625L14.4371 23.1444L20.6307 3.01532L21.0855 1.53468L18.1242 0.625ZM21.6032 7.20564L22.6968 8.29919L27.0226 12.625L22.6968 16.9508L21.6032 18.0444L23.7952 20.2363L24.8887 19.1427L30.3081 13.7234L31.4065 12.625L30.3129 11.5315L24.8936 6.1121L23.7952 5.01371L21.6032 7.20564ZM8.3113 5.01371L7.21775 6.10726L1.7984 11.5266L0.700012 12.625L1.79356 13.7185L7.21292 19.1379L8.3113 20.2363L10.5032 18.0444L9.40969 16.9508L5.08388 12.625L9.40969 8.29919L10.5032 7.20564L8.3113 5.01371Z"
                fill="#52A8EF"
            />
        </svg>
    );
}
