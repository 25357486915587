import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-solid-svg-icons";
import { Button } from "@cpchem/covalence-ui";

import "./styles.scss";

export type AccordionProps = {
    children: React.ReactNode;
    className?: string;
    defaultOpen?: boolean;
    label: string;
    testId?: string;
    isOpenIcon?: JSX.Element;
    isClosedIcon?: JSX.Element;
};

export function Accordion({
    children,
    className,
    label,
    defaultOpen = false,
    testId,
    isOpenIcon,
    isClosedIcon,
}: AccordionProps): JSX.Element {
    const [isOpen, toggleOpen] = useState(defaultOpen);

    let cn = "accordion";

    if (className) {
        cn += ` ${className}`;
    }

    function handleToggle() {
        toggleOpen(!isOpen);
    }
    const toggleIcon = isOpen ? isOpenIcon : isClosedIcon;

    const defaultIcon = (
        <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} />
    );

    const buttonTestId = testId ? `${testId}-button` : "accordion-button";

    useEffect(() => {
        toggleOpen(defaultOpen);
    }, [defaultOpen]);

    return (
        <div className={cn} data-testid={testId}>
            <div className="header">
                <Button
                    onClick={handleToggle}
                    icon={toggleIcon || defaultIcon}
                    isIconAfterText
                    testId={buttonTestId}
                    text={label}
                    variant="text"
                    className="accordion-button"
                />
            </div>
            {isOpen && <div className="content">{children}</div>}
        </div>
    );
}
