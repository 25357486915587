import React from "react";
import CreateCampaign from "./components/create-campaign";
import { RestrictedBy } from "@cpchem/azure-auth-react";
import { TestIds } from "../../test-ids";
import { useUARContext } from "@pages/user-access-reviews-page/context";
import { Navigate } from "react-router-dom";
import { routePath } from "../../../../app/route-paths";
import { StatsAndTableView } from "../stats-and-table-view";

import "./styles.scss";

function CurrentCampaignView(): JSX.Element {
    const { activeCampaign } = useUARContext();
    return (
        <div
            className="current-campaign-view"
            data-testid={TestIds.CurrentCampaignView}
        >
            {activeCampaign ? (
                <StatsAndTableView testId={TestIds.CurrentCampaignView} />
            ) : (
                <CreateCampaign testId={TestIds.CreateCampaignView} />
            )}
        </div>
    );
}

export default RestrictedBy({
    approvedRoles: ["Owners"],
    unauthorizedView: <Navigate replace to={routePath.USER_ACCESS_REVIEWS} />,
})(CurrentCampaignView);
