import React, { useEffect, useRef, useState } from "react";
import "./styles.scss";
import { DecisionStatus } from "@services/cerberus/model";
import {
    getDecisionStatus,
    decisionOptions,
} from "@pages/user-access-reviews-page/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons";

interface DecisionSelectProps {
    onChangeDecision: (decision: DecisionStatus) => void;
    decisionStatus: DecisionStatus | null;
    testId?: string;
}

export function DecisionSelect({
    decisionStatus,
    testId,
    onChangeDecision,
}: DecisionSelectProps) {
    const [showDropdown, setShowDropdown] = useState<boolean>(false);

    const badgeRef = useRef<HTMLDivElement>(null);

    const dropdownRef = useRef<HTMLDivElement>(null);

    function handleOnChangeDecision(decision: DecisionStatus | string) {
        if (decision === decisionStatus) {
            return;
        }

        onChangeDecision(Number(decision));
        handleHideDropdown();
    }

    function handleShowDropdown() {
        setShowDropdown(true);
    }

    function handleHideDropdown() {
        setShowDropdown(false);
    }

    let selectCn = "select-box";

    const { selectLabel, severity } = getDecisionStatus(decisionStatus);

    let selectOptions = decisionOptions;

    selectOptions = selectOptions.filter(
        (option) => option.value !== "in-queue"
    );

    if (severity) {
        selectCn += ` ${severity}`;
    }

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target as Node) &&
                !badgeRef.current?.contains(event.target as Node)
            ) {
                handleHideDropdown();
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdownRef]);

    return (
        <div className="decision-select">
            <div
                data-testid={`${testId}-select`}
                className="select-container"
                onClick={handleShowDropdown}
                role="button"
                tabIndex={0}
                onKeyDown={handleShowDropdown}
                ref={badgeRef}
            >
                <div className={selectCn}>
                    <span
                        className="label"
                        data-testid={`${testId}-select-value`}
                    >
                        {selectLabel}
                    </span>
                    <FontAwesomeIcon
                        className="chevron-icon"
                        icon={faChevronDown}
                    />
                </div>
            </div>
            <div className="dropdown" data-testid={`${testId}-select-dropdown`}>
                {showDropdown && (
                    <div className="dropdown-content" ref={dropdownRef}>
                        {selectOptions?.map((option) => (
                            <div
                                key={option.label}
                                data-testid={`${testId}-option-${option.value}`}
                                onClick={() =>
                                    handleOnChangeDecision(option.value)
                                }
                                onKeyDown={() =>
                                    handleOnChangeDecision(option.value)
                                }
                                role="button"
                                tabIndex={0}
                                className="dropdown-option"
                            >
                                {option.label}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
}
