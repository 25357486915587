import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button } from "@cpchem/covalence-ui";
import { DevSpotModal } from "@components";
import { TestIds } from "@pages/user-access-reviews-page/test-ids";
import { ModalSeverity, ModalState, severityMap } from "./constants";

import "./style.scss";

export interface StateModalProps extends ModalState {
    isOpen: boolean;
    testId?: string;
    hasIcon?: boolean;
}

export function StateModal({
    testId,
    hasIcon = true,
    severity = ModalSeverity.Caution,
    title,
    subText,
    contentHeader,
    contentText,
    isOpen,
    actionButtonClassName,
    actionButtonText,
    actionButtonColor,
    closeButtonText = "Return To App",
    closeButtonColor = "primary",
    closeButtonVariant = "outline",
    closeButtonClassName,
    onActionClick,
    onClose,
}: StateModalProps): JSX.Element {
    const {
        icon,
        className: stateClassName,
        title: defaultTitle,
    } = severityMap[severity];

    function handleOnClose() {
        if (onClose) {
            onClose();
        }
    }

    return (
        <DevSpotModal
            isOpen={isOpen}
            onRequestClose={handleOnClose}
            className="state-modal"
            testId={testId}
            header={
                <div className="state-modal-header">
                    {hasIcon && (
                        <div className={`icon-background ${stateClassName}`}>
                            <FontAwesomeIcon icon={icon} className="icon" />
                        </div>
                    )}
                    <p data-testid={TestIds.StateModalTitle} className="title">
                        {title || defaultTitle}
                    </p>
                    {subText && (
                        <p
                            className="subtext"
                            data-testid={TestIds.StateModalSubText}
                        >
                            {subText}
                        </p>
                    )}
                </div>
            }
            footer={
                <div className="state-modal-footer">
                    {onClose && (
                        <Button
                            className={`close-button ${closeButtonClassName}`}
                            text={closeButtonText}
                            onClick={onClose}
                            color={closeButtonColor}
                            variant={closeButtonVariant}
                            testId={TestIds.StateModalCloseButton}
                        />
                    )}
                    {onActionClick && (
                        <Button
                            className={`action-button ${actionButtonClassName}`}
                            text={actionButtonText}
                            onClick={onActionClick}
                            variant="solid"
                            color={actionButtonColor}
                            testId={TestIds.StateModalActionButton}
                        />
                    )}
                </div>
            }
        >
            <div className="state-modal-content">
                {contentHeader && (
                    <div className="header">
                        <p>{contentHeader}</p>
                    </div>
                )}
                {contentText && (
                    <div
                        data-testid={TestIds.StateModalDescription}
                        className="text"
                    >
                        {contentText}
                    </div>
                )}
            </div>
        </DevSpotModal>
    );
}
