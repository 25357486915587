import React from "react";
import { faDash, faMessages } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getDecisionStatus } from "@pages/user-access-reviews-page/constants";
import { Review } from "@services/cerberus/model";

import { capitalizeFirstLetter } from "@utilities/text";
import { DetailedAvatar } from "../review-details/components/avatar-with-details";
import { TestIds } from "@pages/user-access-reviews-page/test-ids";

export const noDataRender = () => (
    <div className="no-data" data-testid={TestIds.CellRenders.NoDataRender}>
        <FontAwesomeIcon icon={faDash} className="icon" />
    </div>
);

export const repositoryRender = ({
    repositoryName,
    repositoryDescription,
}: {
    repositoryName: Review["repositoryName"];
    repositoryDescription?: Review["repositoryDescription"];
}) => {
    return (
        <div className="column repository">
            <p className="name">{repositoryName}</p>
            {repositoryDescription && (
                <p className="description">{repositoryDescription}</p>
            )}
        </div>
    );
};

export const decisionStatusRender = (
    decisionStatus: Review["decisionStatus"]
) => {
    if (decisionStatus === null) {
        return noDataRender();
    }

    const { icon, statusText, className } = getDecisionStatus(decisionStatus);

    return (
        <div className={`column decision-status ${className}`}>
            <div className="icon">
                <FontAwesomeIcon
                    icon={icon}
                    aria-label={`Icon for ${decisionStatus} status`}
                />
            </div>
            <p className="status">{capitalizeFirstLetter(statusText)}</p>
        </div>
    );
};

export const reviewedByRender = (reviewedBy: Review["reviewedBy"]) => {
    if (!reviewedBy) {
        return noDataRender();
    }

    return (
        <div className={`column reviewer`}>
            <DetailedAvatar
                name={reviewedBy.displayName}
                avatarUrl={reviewedBy.avatarUrl}
                randomizeColor
            />
        </div>
    );
};

export const commentsCountRender = (commentsCount: Review["commentsCount"]) => {
    if (!commentsCount) {
        return noDataRender();
    }
    return (
        <div className="column comments">
            <FontAwesomeIcon icon={faMessages} className="icon" />
            <p className="count">{commentsCount}</p>
        </div>
    );
};
