import { AuthManager } from "@cpchem/azure-auth";
import { getTraceParent } from "@cpchem/logging";
import { AuthServiceKey, ServiceLocator } from "@services";
import { FetchOptions, FetchOptionMethod } from "@services/fetch-interfaces";
import { FetchInterceptorService } from "./interface";

export class FetchInterceptorImplementation implements FetchInterceptorService {
    private readonly authManager: AuthManager;
    constructor() {
        this.authManager = ServiceLocator.get<AuthManager>(AuthServiceKey);
    }
    async getFetchOptionsAsync(
        scopes: string[],
        method: FetchOptionMethod,
        body?: string | FormData
    ): Promise<FetchOptions> {
        const token = await this.authManager.getApiToken(scopes);
        const headers = new Headers();
        const bearer = `Bearer ${token}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type", "application/json");
        headers.append("traceparent", getTraceParent());

        return {
            method,
            headers,
            body,
            credentials: "same-origin",
            mode: "cors",
        };
    }
}
