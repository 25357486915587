import React from "react";
import { States } from "@cpchem/covalence-ui";
import "./styles.scss";
interface PillProps {
    testId?: string;
    icon: JSX.Element;
    status: string;
    count?: number;
    onClick?: () => void;
    isActive?: boolean;
    severity?: States;
}

export default function Pill({
    testId,
    icon,
    status,
    count,
    onClick,
    isActive,
    severity,
}: PillProps) {
    let cn = "pill";
    if (isActive) {
        cn += " active";
    }
    if (onClick) {
        cn += " interactable";
    }
    if (severity) {
        cn += ` ${severity}`;
    }
    return (
        <div
            className={cn}
            data-testid={testId}
            onClick={onClick}
            onKeyDown={onClick}
            tabIndex={0}
            role="button"
        >
            <div className="status-content">
                <span className="icon">{icon}</span>
                <p className="status">{status}</p>
            </div>

            <p className="count">{count}</p>
        </div>
    );
}
