import React from "react";
import { useNavigate } from "react-router-dom";
import { AppBar as CovalenceAppBar } from "@cpchem/covalence-ui";
import { AppIcon } from "@components/icons/app-icon";
import { routePath } from "../../route-paths";

import "./styles.scss";

export function AppBar(): JSX.Element {
    const navigate = useNavigate();

    function handleIconClick() {
        navigate(routePath.LANDING);
    }

    return (
        <CovalenceAppBar
            className="appbar"
            applicationName="DevSpot"
            icon={<AppIcon />}
            onIconClick={handleIconClick}
        />
    );
}
