import React from "react";
import { Avatar, Divider } from "@cpchem/covalence-ui";
import { getInitialsFromName } from "@utilities/text";
import { getColorFromName } from "@utilities/random";
import { DetailedAvatar } from "../avatar-with-details";
import { TeamMember } from "@services/cerberus/model";

import "./styles.scss";

interface CollaboratorProps {
    collaboratorName: string;
    collaboratorUsername: string | null;
    avatarUrl: string | null;
    teamMembers: TeamMember[] | null;
    testId?: string;
}

export function Collaborator({
    collaboratorName,
    collaboratorUsername,
    avatarUrl,
    teamMembers,
    testId,
}: CollaboratorProps) {
    return (
        <div
            className="collaborator-container"
            key={`${collaboratorName}-${collaboratorUsername}`}
            data-testid={testId}
        >
            <div
                className={`collaborator ${getColorFromName(collaboratorName)}`}
            >
                <Avatar
                    alt="avatar"
                    src={avatarUrl || undefined}
                    initials={
                        avatarUrl ? "" : getInitialsFromName(collaboratorName)
                    }
                    className="avatar"
                    data-testid={`${testId}-avatar`}
                />
                <div className="details" data-testid={`${testId}-details`}>
                    <p className="name" data-testid={`${testId}-name`}>
                        {collaboratorName}
                    </p>
                    <p className="username" data-testid={`${testId}-username`}>
                        {collaboratorUsername}
                        {teamMembers &&
                            `• ${teamMembers.length} ${
                                teamMembers.length > 1 ? "Members" : "Member"
                            }`}
                    </p>
                </div>
            </div>

            {teamMembers && (
                <>
                    <Divider className="divider" />
                    <div
                        className="member-list"
                        data-testid={`${testId}-team-members`}
                    >
                        {teamMembers.map(({ avatarUrl, displayName }) => (
                            <DetailedAvatar
                                key={displayName}
                                name={displayName}
                                randomizeColor
                                avatarUrl={avatarUrl}
                                className="member"
                                testId={`${testId}-team-member-${displayName}`}
                            />
                        ))}
                    </div>
                </>
            )}
        </div>
    );
}
