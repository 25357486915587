/* eslint-disable no-empty-pattern */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from "react";
import { RestrictedBy } from "@cpchem/azure-auth-react";
import { TestIds } from "@pages/user-access-reviews-page/test-ids";
import { Navigate } from "react-router-dom";
import "./styles.scss";
import { routePath } from "../../../../app/route-paths";
import { useUARContext } from "@pages/user-access-reviews-page/context";
import { useService } from "../../../../service-provider";
import { CerberusService, CerberusServiceKey } from "@services";
import { GetClosedCampaignsAPIResponse } from "@services/cerberus/interface";
import ClosedCampaignsTable from "./components/closed-campaign-table";
import { LoadingIndicator } from "@components/loading-indicator";

export function ClosedCampaignsView(): JSX.Element {
    const cerberusService = useService<CerberusService>(CerberusServiceKey);
    const { isLoading, onSetLoading } = useUARContext();
    const initialData: GetClosedCampaignsAPIResponse = [];
    const [closedCampaigns, setClosedCampaigns] =
        useState<GetClosedCampaignsAPIResponse>(initialData);

    async function getClosedCampaigns() {
        try {
            onSetLoading(true);
            const response = await cerberusService.GetClosedCampaigns();
            if (response.data) {
                const data = response.data as GetClosedCampaignsAPIResponse;
                setClosedCampaigns(data);
            }
        } catch (error) {
            setClosedCampaigns(initialData);
        } finally {
            onSetLoading(false);
        }
    }

    useEffect(() => {
        getClosedCampaigns();
    }, []);

    return (
        <div
            className="closed-campaigns-view"
            data-testid={TestIds.ClosedCampaignsView}
        >
            {isLoading ? (
                <LoadingIndicator />
            ) : (
                <ClosedCampaignsTable closedCampaignsData={closedCampaigns} />
            )}
        </div>
    );
}
export default RestrictedBy({
    approvedRoles: ["Owners"],
    unauthorizedView: <Navigate replace to={routePath.USER_ACCESS_REVIEWS} />,
})(ClosedCampaignsView);
