import { Colors, States } from "@cpchem/covalence-ui";
import {
    faCheck,
    faTimes,
    faExclamation,
} from "@fortawesome/pro-light-svg-icons";

export enum ModalSeverity {
    Success = "success",
    Danger = "danger",
    Caution = "caution",
    Info = "info",
    AreYouSure = "areyousure,",
}

export interface ModalState {
    severity?: ModalSeverity;
    title?: string;
    subText?: string;
    contentHeader?: string;
    contentText?: string;
    actionButtonText?: string;
    actionButtonClassName?: string;
    onActionClick?: () => void;
    onClose?: () => void;
    actionButtonColor?: States;
    closeButtonText?: string;
    closeButtonColor?: Colors;
    closeButtonVariant?: "solid" | "outline" | "text";
    closeButtonClassName?: string;
    modalSize?: "small" | "medium" | "large" | "fullscreen";
}

export const severityMap = {
    [ModalSeverity.Success]: {
        icon: faCheck,
        className: "success",
        title: "Success",
    },
    [ModalSeverity.Danger]: {
        icon: faTimes,
        className: "danger",
        title: "Danger",
    },
    [ModalSeverity.Caution]: {
        icon: faExclamation,
        className: "caution",
        title: "Warning",
    },
    [ModalSeverity.AreYouSure]: {
        icon: faExclamation,
        className: "caution",
        title: "Are You Sure?",
    },
    [ModalSeverity.Info]: {
        icon: faExclamation,
        className: "info",
        title: "Info",
    },
};
