import React, { useEffect, useMemo, useState } from "react";
import { CampaignDetails } from "../campaign-details";
import { Alert } from "@cpchem/covalence-ui";
import ReviewsTable from "../reviews-table";
import { RowSelectionState, Updater } from "@tanstack/react-table";
import { TestIds } from "../../test-ids";
import { ReviewCounts } from "./components/review-counts";
import { useUARContext } from "@pages/user-access-reviews-page/context";
import { CerberusService, CerberusServiceKey } from "@services";
import { GetReviewsAPIResponse } from "@services/cerberus/interface";
import { useService } from "../../../../service-provider";
import ReviewDetails from "../review-details";
import { DecisionStatus, Review } from "@services/cerberus/model";

import "./styles.scss";

const initialDataState: GetReviewsAPIResponse = {
    reviews: [],
    completeReviewCount: 0,
    inQueueReviewCount: 0,
    unsureReviewCount: 0,
};

interface StatsAndTableProps {
    showInfoMessage?: boolean;
    showReviewsAssignedToMe?: boolean;
    testId?: string;
}

export function StatsAndTableView({
    showInfoMessage,
    showReviewsAssignedToMe,
    testId: incomingTestId,
}: StatsAndTableProps): JSX.Element {
    const cerberusService = useService<CerberusService>(CerberusServiceKey);

    const {
        onSetLoading,
        isMobileScreen,
        currentAccount,
        activeCampaign,
        onSyncCampaign,
    } = useUARContext();

    const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

    const [data, setData] = useState<GetReviewsAPIResponse>(initialDataState);

    const [reviewDetailsClassName, setReviewDetailsClassName] =
        React.useState<string>("review-details");

    const [filterByDecision, setFilterByDecision] =
        useState<DecisionStatus | null>();

    const filteredReviews = useMemo(() => {
        return data.reviews.filter((review) => {
            if (filterByDecision === undefined) {
                return review;
            }

            return review.decisionStatus === filterByDecision;
        });
    }, [data.reviews, filterByDecision]);

    const testId = incomingTestId || TestIds.StatsAndReviewsTableView;

    const selectedReviewIndex = Object.keys(rowSelection).toString();

    const selectedReview = selectedReviewIndex
        ? filteredReviews[Number(selectedReviewIndex)]
        : null;

    async function getReviews() {
        try {
            if (!activeCampaign) {
                return;
            }
            onSetLoading(true);
            const response = await cerberusService.GetReviews({
                campaignId: activeCampaign.id,
                showAssigned: !!showReviewsAssignedToMe,
            });

            if (response.data) {
                const data = response.data as GetReviewsAPIResponse;
                setData(data);
            }
        } catch (error) {
            setData(initialDataState);
        } finally {
            onSetLoading(false);
        }
    }

    function handleUpdateReviewData(updatedReview: Review) {
        const updatedReviews = data.reviews.map((review) => {
            if (review.id === updatedReview.id) {
                return updatedReview;
            }
            return review;
        });
        updateReviewCounts(updatedReviews);
    }

    function handleRemoveReview(removedReview: Review) {
        const updatedReviews = data.reviews.filter(
            (review) => review.id !== removedReview.id
        );
        handleCloseReviewDetails();
        updateReviewCounts(updatedReviews);
    }

    function updateReviewCounts(reviews: Review[]) {
        const newInQueueReviewCount = reviews.filter(
            (review) => review.decisionStatus === null
        ).length;
        const newCompleteReviewCount = reviews.filter(
            (review) => review.decisionStatus === DecisionStatus.Completed
        ).length;
        const newUnsureReviewCount = reviews.filter(
            (review) => review.decisionStatus === DecisionStatus.Unsure
        ).length;

        setData({
            inQueueReviewCount: newInQueueReviewCount,
            completeReviewCount: newCompleteReviewCount,
            unsureReviewCount: newUnsureReviewCount,
            reviews: reviews,
        });

        onSyncCampaign({
            inQueueReviewCount: newInQueueReviewCount,
            completeReviewCount: newCompleteReviewCount,
            unsureReviewCount: newUnsureReviewCount,
            totalReviewCount:
                newInQueueReviewCount +
                newCompleteReviewCount +
                newUnsureReviewCount,
        });
    }

    function handleOnRowSelectionChanged(
        updaterOrValue: Updater<RowSelectionState>
    ) {
        setRowSelection(updaterOrValue);
        setReviewDetailsClassName(`review-details enter-animation`);
    }

    function handleCloseReviewDetails() {
        setReviewDetailsClassName(`review-details exit-animation`);
        setRowSelection({});
    }

    function handleOnFilterByDecision(decision: DecisionStatus | null) {
        if (decision === filterByDecision) {
            setRowSelection({});
            return setFilterByDecision(undefined);
        }
        setRowSelection({});
        return setFilterByDecision(decision);
    }

    useEffect(() => {
        getReviews();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeCampaign?.id]);

    return (
        <div className="stats-and-review-table-view">
            <div className="stats-and-review-table">
                <div className="stats">
                    {showInfoMessage && (
                        <div
                            className="header"
                            data-testid={`${testId}-${TestIds.StatsAndReviewsTableViewHeader}`}
                        >
                            <p
                                className="title"
                                data-testid={`${testId}-${TestIds.StatsAndReviewsTableViewTitle}`}
                            >
                                Hello, {currentAccount?.name}
                            </p>
                            <p
                                className="welcome-message"
                                data-testid={`${testId}-${TestIds.StatsAndReviewsTableViewWelcomeMessage}`}
                            >
                                Welcome to GitHub User Access Reviews. Here you
                                can review and comment on repository access.
                            </p>
                        </div>
                    )}
                    {activeCampaign && !showReviewsAssignedToMe ? (
                        <CampaignDetails
                            campaign={activeCampaign}
                            testId={`${testId}-${TestIds.CampaignDetails}`}
                            decisionToFilterBy={filterByDecision}
                            onFilterByDecision={handleOnFilterByDecision}
                            reviewCounts={{
                                inQueueReviewCount: data.inQueueReviewCount,
                                completeReviewCount: data.completeReviewCount,
                                unsureReviewCount: data.unsureReviewCount,
                            }}
                        />
                    ) : (
                        <div className="review-counts">
                            <ReviewCounts
                                reviewCounts={{
                                    inQueueReviewCount: data.inQueueReviewCount,
                                    completeReviewCount:
                                        data.completeReviewCount,
                                    unsureReviewCount: data.unsureReviewCount,
                                }}
                                testId={`${testId}-${TestIds.ReviewCounts}`}
                                decisionToFilterBy={filterByDecision}
                                onFilterByDecision={handleOnFilterByDecision}
                            />
                        </div>
                    )}
                </div>
                {data.reviews.length === 0 ? (
                    <div className="alert-container">
                        <Alert
                            message="No Reviews"
                            severity="info"
                            className="alert"
                            testId={`${testId}-${TestIds.NoReviewsAlert}`}
                        />
                    </div>
                ) : (
                    <ReviewsTable
                        data={filteredReviews}
                        selectedRow={rowSelection}
                        onRowSelectionChange={handleOnRowSelectionChanged}
                        testId={`${testId}-${TestIds.ReviewsView}`}
                        isMobile={isMobileScreen}
                    />
                )}
            </div>
            {selectedReview && (
                <ReviewDetails
                    selectedReview={selectedReview}
                    className={reviewDetailsClassName}
                    testId={`${testId}-${TestIds.ReviewDetails}`}
                    onClose={handleCloseReviewDetails}
                    onReviewDataSync={handleUpdateReviewData}
                    onRemoveReview={handleRemoveReview}
                />
            )}
        </div>
    );
}
