import React, { memo } from "react";
import { Review } from "@services/cerberus/model";
import { Row, flexRender } from "@tanstack/react-table";
import { TestIds } from "@pages/user-access-reviews-page/test-ids";

interface TableRowProps {
    row: Row<Review>;
    isSelected?: boolean;
    testId?: string;
}

function TableRow({ row, isSelected, testId }: TableRowProps) {
    return (
        <tr
            data-testid={`${testId}-${TestIds.ReviewsTableRow}-${row.id}`}
            className={isSelected ? "selected" : ""}
            onClick={isSelected ? undefined : row.getToggleSelectedHandler()}
        >
            {row.getVisibleCells().map((cell, index) => (
                <td
                    data-testid={`${testId}-${TestIds.ReviewsTableRowCell}-${cell.id}`}
                    key={cell.id}
                    className={
                        index === row.getVisibleCells().length - 1
                            ? "is-last-row-cell"
                            : ""
                    }
                >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
            ))}
        </tr>
    );
}

export default memo(TableRow);
