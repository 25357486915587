import { routePath } from "../../app/route-paths";
import { faCheckCircle, faDash } from "@fortawesome/pro-light-svg-icons";
import {
    faExclamationCircle,
    IconDefinition,
} from "@fortawesome/pro-regular-svg-icons";
import { DecisionStatus, Role } from "@services/cerberus/model";
import { ModalSeverity, ModalState } from "@components/state-modal/constants";
import { States } from "@cpchem/covalence-ui";

export enum SideBarItems {
    CurrentCampaign = "current-campaign",
    MyReviews = "my-reviews",
    ClosedCampaign = "closed-campaign",
}

export const routeLocationMap = {
    [routePath.USER_ACCESS_REVIEWS]: SideBarItems.MyReviews,
    [routePath.UAR_CAMPAIGNS_CURRENT]: SideBarItems.CurrentCampaign,
    [routePath.UAR_CAMPAIGNS_CLOSED]: SideBarItems.ClosedCampaign,
};

export interface DecisionStatusMap {
    [key: number | string]: {
        icon: IconDefinition;
        statusText: string;
        className: string;
        sortIndex: number;
        selectValue: DecisionStatus | string;
        selectLabel: string;
        severity: States;
    };
}

export const decisionOptions = [
    {
        label: "Unsure",
        value: DecisionStatus.Unsure,
    },
    {
        label: "Approved",
        value: DecisionStatus.Completed,
    },
    {
        label: "In Queue",
        value: "in-queue",
    },
];

export const decisionStatusMap: DecisionStatusMap = {
    [DecisionStatus.Unsure]: {
        icon: faExclamationCircle,
        statusText: "Unsure",
        className: "unsure",
        sortIndex: 1,
        selectValue: DecisionStatus.Unsure,
        selectLabel: "Unsure",
        severity: "caution",
    },
    ["In Queue"]: {
        icon: faDash,
        statusText: "",
        className: "in-queue",
        sortIndex: 2,
        selectValue: "in-queue",
        selectLabel: "In Queue",
        severity: "caution",
    },
    [DecisionStatus.Completed]: {
        icon: faCheckCircle,
        statusText: "Completed",
        className: "completed",
        sortIndex: 3,
        selectValue: DecisionStatus.Completed,
        selectLabel: "Approved",
        severity: "success",
    },
};

// Assumption: In Queue decision status comes from the backend as null
export function getDecisionStatus(decisionStatus: DecisionStatus | null) {
    if (decisionStatus === null) {
        return decisionStatusMap["In Queue"];
    }
    return decisionStatusMap[decisionStatus];
}

export interface CollaboratorRoleMap {
    [key: string]: {
        description: string;
        sortIndex: number;
    };
}

export const collaboratorRoleMap = {
    [Role.Admin]: {
        role: Role.Admin,
        description:
            "Full access to repositories including sensitive and destructive actions.",
        sortIndex: 1,
    },
    [Role.Maintain]: {
        role: Role.Maintain,
        description:
            "Write permissions plus manage issues, pull requests, and some repository settings.",
        sortIndex: 2,
    },
    [Role.Write]: {
        role: Role.Write,
        description:
            "Triage permissions plus read, clone, and push to repositories.",
        sortIndex: 3,
    },
    [Role.Triage]: {
        role: Role.Triage,
        description: "Read permissions plus manage issues and pull requests.",
        sortIndex: 4,
    },
    [Role.Read]: {
        role: Role.Read,
        description:
            "Read and clone repositories. Open and comment on issues and pull requests.",
        sortIndex: 5,
    },
};

export const errorGettingReviewsModalState: ModalState = {
    severity: ModalSeverity.Danger,
    title: "Error",
    subText: "Failed to retrieve data",
    contentText: "Return to app",
};

export const errorGettingCampaignDetailsModalState: ModalState = {
    severity: ModalSeverity.Danger,
    title: "Error",
    subText: "Failed to retrieve data",
    contentText: "Return to app",
};
