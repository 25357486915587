import React from "react";
import { TestIds } from "@pages/user-access-reviews-page/test-ids";
import Pill from "../pill";
import {
    faCircleExclamation,
    faCircleCheck,
    faClock,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DecisionStatus } from "@services/cerberus/model";

import "./styles.scss";

interface ReviewCountsProps {
    className?: string;
    testId?: string;
    reviewCounts: {
        inQueueReviewCount: number;
        unsureReviewCount: number;
        completeReviewCount: number;
    };
    decisionToFilterBy: DecisionStatus | null | undefined;
    onFilterByDecision: (decision: DecisionStatus | null) => void;
}
export function ReviewCounts({
    className,
    reviewCounts,
    testId: incomingTestId,
    decisionToFilterBy,
    onFilterByDecision,
}: ReviewCountsProps): JSX.Element {
    let cn = "pills";
    if (className) {
        cn = `${className} pills`;
    }
    const testId = incomingTestId || TestIds.ReviewCounts;
    return (
        <div className={cn} data-testid={testId}>
            <Pill
                icon={
                    <FontAwesomeIcon
                        className="icon"
                        icon={faCircleExclamation}
                    />
                }
                status="Unsure"
                count={reviewCounts.unsureReviewCount}
                testId={`${testId}-${TestIds.ReviewCountPill}-unsure`}
                onClick={() => onFilterByDecision(DecisionStatus.Unsure)}
                isActive={decisionToFilterBy === DecisionStatus.Unsure}
                severity="caution"
            />
            <Pill
                icon={<FontAwesomeIcon className="icon" icon={faClock} />}
                status="In Queue"
                count={reviewCounts.inQueueReviewCount}
                testId={`${testId}-${TestIds.ReviewCountPill}-inqueue`}
                onClick={() => onFilterByDecision(null)}
                isActive={decisionToFilterBy === null}
                severity="info"
            />
            <Pill
                icon={<FontAwesomeIcon className="icon" icon={faCircleCheck} />}
                status="Completed"
                count={reviewCounts.completeReviewCount}
                testId={`${testId}-${TestIds.ReviewCountPill}-completed`}
                onClick={() => onFilterByDecision(DecisionStatus.Completed)}
                isActive={decisionToFilterBy === DecisionStatus.Completed}
                severity="success"
            />
        </div>
    );
}
