import React from "react";
import { DetailedAvatar } from "../avatar-with-details";

import "./styles.scss";

interface CommentProps {
    addedBy: {
        displayName: string;
        samlId: string;
        avatarUrl: string | null;
    };
    addedDate: string;
    comment: string;
    testId?: string;
}

export function Comment({ addedBy, addedDate, comment, testId }: CommentProps) {
    return (
        <div
            className="comment"
            key={`${addedDate}-${addedBy.displayName}`}
            data-testid={testId}
        >
            <div className="header" data-testid={`${testId}-header`}>
                <DetailedAvatar
                    avatarUrl={addedBy.avatarUrl}
                    name={addedBy.displayName}
                    randomizeColor
                    timeStamp={addedDate}
                    testId={`${testId}-detailed-avatar`}
                />
            </div>

            <div className="body" data-testid={`${testId}-body`}>
                <p className="message" data-testid={`${testId}-comment`}>
                    {comment}
                </p>
            </div>
        </div>
    );
}
