export function capitalizeFirstLetter(string: string | undefined | null) {
    if (!string) {
        return "";
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getInitialsFromName(name: string) {
    const names = name.split(" ");

    let initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
}
