import React from "react";

import "./styles.scss";
import {
    Modal,
    ModalHeader,
    ModalFooter,
    ModalContent,
    ModalProps,
} from "@cpchem/covalence-ui";

export interface ModalContents extends ModalProps {
    header?: React.ReactNode;
    footer?: React.ReactNode;
}

export function DevSpotModal(props: ModalContents): JSX.Element {
    const { className } = props;
    return (
        <Modal {...props}>
            <ModalHeader className={`${className}-header`}>
                {props.header}
            </ModalHeader>
            <ModalContent className={`${className}-content`}>
                {props.children}
            </ModalContent>
            <ModalFooter className={`${className}-footer`}>
                {props.footer}
            </ModalFooter>
        </Modal>
    );
}
