import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TestIds } from "../test-ids";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
import "./styles.scss";

export function LoadingIndicator() {
    return (
        <div
            className="loading-indicator"
            data-testid={TestIds.LoadingIndicator}
        >
            <FontAwesomeIcon icon={faSpinnerThird} spin className="spinner" />
        </div>
    );
}
