import { ModalState } from "@components/state-modal/constants";
import { AccountInformation } from "@cpchem/azure-auth";
import { GetClosedCampaignsAPIResponse } from "@services/cerberus/interface";
import { Campaign, Reviewer } from "@services/cerberus/model";
import { useOutletContext } from "react-router-dom";

type UserAccessReviewsContext = {
    isLoading: boolean;
    onSetLoading: (isLoading: boolean) => void;
    activeCampaign: Campaign | null;
    closedCampaigns: GetClosedCampaignsAPIResponse;
    closedCampaignId: string;
    currentAccount: AccountInformation | null;
    reviewer: Reviewer | null;
    isOwner: boolean;
    isMobileScreen: boolean;
    onSetReviewer: (reviewer: Reviewer) => void;
    onSetClosedCampaignId: (id: string) => void;
    onOpenStateModal: (state: ModalState) => void;
    onCloseStateModal: () => void;
    onSyncCampaign: (campaignUpdate: Partial<Campaign>) => void;
};

export function useUARContext(): UserAccessReviewsContext {
    return useOutletContext<UserAccessReviewsContext>();
}
