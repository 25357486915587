import { Config } from "@config";
import { FEATURE_FLAG_EVENT_TYPE } from "@cpchem/featureflags";
import {
    LogEvent,
    LogManager,
    logsAtOrAbove,
    LogLevel,
    getAppVersionFromMetaTag,
} from "@cpchem/logging";

export function configureLogging(config: Config): void {
    LogManager.initialize({
        appVersion: getAppVersionFromMetaTag(),
        appInsights: config.logging.appInsights,
    });

    const environmentSpecificLogFilter = logsAtOrAbove(
        config.env.toUpperCase() === "DEVELOPMENT"
            ? LogLevel.INFO
            : LogLevel.ERROR
    );

    LogManager.current().subscribeToLogs(
        consoleLogger,
        environmentSpecificLogFilter
    );

    LogManager.current().subscribeToEvents(
        featureFlagEventLogger,
        (type: string) => type === FEATURE_FLAG_EVENT_TYPE
    );
    LogManager.current().subscribeToEvents(
        consoleEventLogger,
        (type: string) => type !== FEATURE_FLAG_EVENT_TYPE
    );
}

function consoleLogger(level: string, message: string): void {
    console.log(`${level}: ${message}`);
}

function consoleEventLogger(event: LogEvent): void {
    console.log(`Logged event: ${event.type}. Contents: ${event.contents}`);
}

function featureFlagEventLogger(event: LogEvent): void {
    console.log(`Logged event: ${event.type}. Contents: ${event.contents}`);
}
