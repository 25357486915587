import React from "react";
import { SideBarListItem } from "./models";
import { TestIds } from "../test-ids";

import "./styles.scss";

interface SideBarProps {
    title: string;
    listItems: SideBarListItem[];
    activeItem?: string;
}

export function SideBar({
    title,
    listItems,
    activeItem,
}: SideBarProps): JSX.Element {
    const sideBarTestId = TestIds.SideBar;
    const sideBarTitleTestId = `${sideBarTestId}-title`;
    const listItemsContainerTestId = `${sideBarTestId}-list-item-container`;
    const listItemsTestId = `${sideBarTestId}-list-item`;
    return (
        <div className="side-bar" data-testid={sideBarTestId}>
            <p className="title" data-testid={sideBarTitleTestId}>
                {title}
            </p>
            <div className="content" data-testid={listItemsContainerTestId}>
                {listItems.map(({ id, label, icon, onClick }) => {
                    let buttonCn = "list-item";
                    if (id === activeItem) {
                        buttonCn += " active";
                    }
                    return (
                        <div
                            key={id}
                            className={buttonCn}
                            data-testid={`${listItemsTestId}-${id}`}
                            onClick={onClick}
                            onKeyDown={onClick}
                            role="button"
                            tabIndex={0}
                        >
                            <span className="icon">{icon}</span>
                            <p className="label">{label}</p>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
