import React from "react";
import "./styles.scss";

export type GhostVariant = "circular" | "rectangular" | "rounded";

interface GhostProps {
    className?: string;
    variant?: GhostVariant;
    height?: number | string;
    width?: number | string;
}

export function Ghost({
    className,
    variant = "rectangular",
    width = "100%",
    height = "100%",
}: GhostProps) {
    let cn = "ghost";
    if (className) {
        cn += ` ${className}`;
    }
    if (variant) {
        cn += ` ${variant}`;
    }
    return (
        <div
            className={cn}
            style={{
                width,
                height,
            }}
        />
    );
}
