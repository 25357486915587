import { TestIds } from "@pages/user-access-reviews-page/test-ids";
import { collaboratorRoleMap } from "@pages/user-access-reviews-page/constants";
import { RepoCollaborator } from "@services/cerberus/model";
import React from "react";
import { AccessRole } from "./components/access-role";

interface AccessRolesProps {
    repoCollaborators: RepoCollaborator[];
    expandRoles?: boolean;
    testId?: string;
}

export function AccessRoles({
    repoCollaborators,
    expandRoles,
    testId = TestIds.ReviewDetails,
}: AccessRolesProps) {
    const collaboratorRoles = Object.values(collaboratorRoleMap);
    return (
        <>
            {collaboratorRoles
                .filter(({ role }) => {
                    const roleCollaborators = repoCollaborators.filter(
                        (collaborator) => collaborator.role === role
                    );
                    return roleCollaborators.length > 0;
                })
                .sort((a, b) => a.sortIndex - b.sortIndex)
                .map(({ role, description }) => {
                    const roleCollaborators = repoCollaborators.filter(
                        (collaborator) => collaborator.role === role
                    );
                    return (
                        <AccessRole
                            testId={testId}
                            key={role}
                            role={role}
                            description={description}
                            isExpanded={expandRoles}
                            repoCollaborators={roleCollaborators}
                        />
                    );
                })}
        </>
    );
}
