import React, { useState } from "react";
import "./styles.scss";
import { getFormattedDate } from "@utilities/date";
import { validateSelectedDate } from "./utils";

export interface DateSelectorProps {
    className?: string;
    testId?: string;
    defaultDate?: Date;
    minDate?: Date;
    maxDate?: Date;
    onChange: (date: Date | undefined) => void;
}

export function DateSelector({
    className,
    testId,
    defaultDate,
    minDate,
    maxDate,
    onChange,
}: DateSelectorProps) {
    const [selectedDate, setSelectedDate] = useState<Date | undefined>(
        defaultDate
    );

    const minDateForRange = (minDate && getFormattedDate(minDate)) || undefined;
    const maxDateForRange = (maxDate && getFormattedDate(maxDate)) || undefined;

    const cnParts = ["date-range", "cui-boxed-input"];

    if (className) {
        cnParts.push(className);
    }
    if (validateSelectedDate(selectedDate, minDate, maxDate)) {
        cnParts.push("valid-date");
    }

    function handleDateChange(event: React.ChangeEvent<HTMLInputElement>) {
        if (!event || !event.target.value) {
            setSelectedDate(undefined);
            onChange(undefined);
            return;
        }
        const newDate = new Date(`${event.target.value}T00:00:00`);
        setSelectedDate(newDate);
        onChange(newDate);
    }

    const cn = cnParts.join(" ");
    return (
        <div className={cn} data-testid={testId}>
            <input
                value={getFormattedDate(selectedDate)}
                data-testid={`${testId}-input`}
                className="date-input"
                type="date"
                onKeyDown={(e) => e.preventDefault()}
                min={minDateForRange}
                max={maxDateForRange}
                onChange={handleDateChange}
            />
        </div>
    );
}
