import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faArrowsRotate,
    faMinus,
    faPlus,
    faTimes,
} from "@fortawesome/pro-light-svg-icons";
import { TestIds } from "../../test-ids";
import { DecisionStatus, Review } from "@services/cerberus/model";
import { noDataRender } from "../reviews-table/cell-renders";
import { DetailedAvatar } from "./components/avatar-with-details";
import { DecisionSelect } from "./components/review-selection";
import { Button, Tooltip } from "@cpchem/covalence-ui";
import { AccessRoles } from "./components/access-roles";
import { CommentsInput } from "./components/comments-input";
import { Comment } from "./components/comment";
import { useUARContext } from "@pages/user-access-reviews-page/context";
import { CerberusService, CerberusServiceKey } from "@services";
import { useService } from "../../../../service-provider";
import { GetReviewByIdAPIResponse } from "@services/cerberus/interface";
import DecisionCommentModal from "../stats-and-table-view/components/decision-comment-modal";
import RefreshView from "./components/refresh-view";
import { errorGettingReviewsModalState } from "@pages/user-access-reviews-page/constants";
import { faUserPen } from "@fortawesome/pro-solid-svg-icons";
import { GhostReviewDetails } from "./components/ghost-body";

import "./styles.scss";

interface ReviewDetailsProps {
    className?: string;
    selectedReview: Review;
    testId?: string;
    onClose: () => void;
    onReviewDataSync: (review: Review) => void;
    onRemoveReview: (review: Review) => void;
}

function ReviewDetails({
    className,
    testId: incomingTestId,
    selectedReview,
    onClose,
    onReviewDataSync,
    onRemoveReview,
}: ReviewDetailsProps) {
    const {
        activeCampaign,
        isMobileScreen,
        currentAccount,
        reviewer,
        onOpenStateModal,
        onCloseStateModal,
    } = useUARContext();

    const cerberusService = useService<CerberusService>(CerberusServiceKey);

    const [review, setReview] = useState<Review | null>(null);

    const [isLoading, setIsLoadingReview] = useState<boolean>(false);

    const [openDecisionCommentModal, setOpenDecisionCommentModal] =
        useState<boolean>(false);

    const [expandRoles, setExpandRoles] = useState<boolean>(true);

    const [comment, setComment] = useState<string>("");

    const [isCommentTextAreaOpen, setIsCommentTextAreaOpen] =
        useState<boolean>(false);

    const testId = incomingTestId || TestIds.ReviewDetails;

    function handleSyncReviewData(review: Review) {
        setReview(review);
        onReviewDataSync(review);
    }

    async function getReview() {
        if (!activeCampaign || !selectedReview) {
            return;
        }
        try {
            setIsLoadingReview(true);
            const response = await cerberusService.GetReviewById({
                campaignId: activeCampaign.id,
                reviewId: selectedReview.id,
            });
            if (response.data) {
                const data = response.data as GetReviewByIdAPIResponse;
                setReview(data);
                return;
            }
            if (response.isNotFound) {
                onRemoveReview(selectedReview);
                setReview(null);
                return;
            }
            throw new Error(response.error);
        } catch (error) {
            onOpenStateModal({
                ...errorGettingReviewsModalState,
                contentText: `${error}`,
                onClose: onCloseStateModal,
            });
        } finally {
            setIsLoadingReview(false);
        }
    }

    async function updateReview(reviewUpdatePayload: Partial<Review>) {
        try {
            if (!activeCampaign || !review) {
                return;
            }
            const response = await cerberusService.UpdateReview({
                campaignId: activeCampaign.id,
                reviewId: review.id,
                reviewUpdatePayload,
            });
            if (response.data) {
                const updatedReview = {
                    ...review,
                    ...reviewUpdatePayload,
                    reviewedBy: {
                        displayName: currentAccount?.name,
                        samlId: currentAccount?.username,
                        avatarUrl: reviewer?.avatarUrl,
                    },
                } as Review;
                handleSyncReviewData(updatedReview);
                return;
            }
            throw new Error(response.error);
        } catch (error) {
            onOpenStateModal({
                ...errorGettingReviewsModalState,
                contentText: `${error}`,
                onClose: onCloseStateModal,
            });
        }
    }

    async function refreshRoles() {
        try {
            if (!activeCampaign || !review) {
                return;
            }
            const response = await cerberusService.RefreshReview({
                campaignId: activeCampaign.id,
                reviewId: review.id,
            });

            if (response.data) {
                const updatedReview = {
                    ...review,
                    isBeingUpdated: true,
                };
                handleSyncReviewData(updatedReview);
                return;
            }
            throw new Error(response.error);
        } catch (error) {
            onOpenStateModal({
                ...errorGettingReviewsModalState,
                contentText: `${error}`,
                onClose: onCloseStateModal,
            });
        }
    }

    async function addComment(comment: string) {
        try {
            if (!activeCampaign || !review || !currentAccount) {
                return;
            }
            const response = await cerberusService.AddCommentToReview({
                campaignId: activeCampaign.id,
                reviewId: review.id,
                comment,
            });

            if (response.data) {
                const updatedReview = {
                    ...review,
                    comments: [
                        ...(review.comments ?? []),
                        {
                            comment,
                            addedBy: {
                                displayName: currentAccount.name,
                                samlId: currentAccount.username,
                                avatarUrl: reviewer?.avatarUrl,
                            },
                            addedDate: new Date().toISOString(),
                        },
                    ],
                    commentsCount: selectedReview.commentsCount + 1,
                } as Review;
                handleSyncReviewData(updatedReview);
                setComment("");
                setIsCommentTextAreaOpen(false);
                return;
            }
            throw new Error(response.error);
        } catch (error) {
            onOpenStateModal({
                ...errorGettingReviewsModalState,
                contentText: `${error}`,
                onClose: onCloseStateModal,
            });
        }
    }

    function handleUpdateDecision(decision: DecisionStatus) {
        if (decision === DecisionStatus.Unsure) {
            handleOpenDecisionCommentModal();
            return;
        }

        const payload = {
            decisionStatus: decision,
        };

        updateReview(payload);
    }

    function handleAddComment() {
        if (!comment) return;
        addComment(comment);
    }

    function onCommentChange(comment: string) {
        setComment(comment);
    }

    function handleToggleRoles() {
        setExpandRoles(!expandRoles);
    }

    function toggleCommentTextArea() {
        setIsCommentTextAreaOpen(!isCommentTextAreaOpen);
    }

    function handleOpenDecisionCommentModal() {
        setOpenDecisionCommentModal(true);
    }

    function handleCloseAddCommentModal() {
        setOpenDecisionCommentModal(false);
    }

    async function updateReviewDecisionWithComment(comment: string) {
        if (!activeCampaign || !review || !currentAccount) {
            return;
        }
        try {
            const updateResponse = await cerberusService.UpdateReview({
                campaignId: activeCampaign.id,
                reviewId: review.id,
                reviewUpdatePayload: {
                    decisionStatus: DecisionStatus.Unsure,
                },
            });

            const addCommentResponse = await cerberusService.AddCommentToReview(
                {
                    campaignId: activeCampaign.id,
                    reviewId: review.id,
                    comment,
                }
            );

            if (updateResponse.data && addCommentResponse.data) {
                const updatedReview = {
                    ...review,
                    reviewedBy: {
                        displayName: currentAccount.name,
                        samlId: currentAccount.username,
                        avatarUrl: reviewer?.avatarUrl,
                    },
                    decisionStatus: DecisionStatus.Unsure,
                    comments: [
                        ...(review.comments ?? []),
                        {
                            comment,
                            addedBy: {
                                displayName: currentAccount.name,
                                samlId: currentAccount.username,
                                avatarUrl: reviewer?.avatarUrl,
                            },
                            addedDate: new Date().toISOString(),
                        },
                    ],
                    commentsCount: selectedReview.commentsCount + 1,
                } as Review;
                handleSyncReviewData(updatedReview);
                return;
            }
            throw new Error(updateResponse.error || addCommentResponse.error);
        } catch (error) {
            onOpenStateModal({
                ...errorGettingReviewsModalState,
                contentText: `${error}`,
                onClose: onCloseStateModal,
            });
        } finally {
            handleCloseAddCommentModal();
        }
    }

    function handleRefreshReview() {
        getReview();
    }

    useEffect(() => {
        if (selectedReview && selectedReview.id !== review?.id) {
            getReview();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedReview]);

    if (!review || isLoading) {
        return (
            <div
                className={className}
                data-testid={`${testId}-${TestIds.ReviewDetailsView}`}
            >
                <GhostReviewDetails />
            </div>
        );
    }

    const {
        repositoryDescription,
        repositoryName,
        reviewedBy,
        repoCollaborators,
        decisionStatus,
        comments,
        isBeingUpdated,
    } = review;

    if (isBeingUpdated) {
        return (
            <RefreshView
                className={className}
                testId={testId}
                onClose={onClose}
                onRefresh={handleRefreshReview}
            />
        );
    }

    return (
        <>
            <div
                className={className}
                data-testid={`${testId}-${TestIds.ReviewDetailsView}`}
            >
                <div className="details">
                    <div
                        className="header"
                        data-testid={`${testId}-${TestIds.ReviewDetailsHeader}`}
                    >
                        <div className="title-container">
                            <p
                                className="title"
                                data-testid={`${testId}-${TestIds.ReviewDetailsRepoTitle}`}
                            >
                                {repositoryName}
                            </p>
                            <FontAwesomeIcon
                                icon={faTimes}
                                className="close-icon"
                                onClick={onClose}
                                data-testid={`${testId}-${TestIds.ReviewDetailsMobileCloseButton}`}
                            />
                        </div>

                        <p
                            className="description"
                            data-testid={`${testId}-${TestIds.ReviewDetailsRepoDescription}`}
                        >
                            {repositoryDescription}
                        </p>
                    </div>
                    <div className="reviewer-decision">
                        <div className="reviewer">
                            <p className="detail-title">Reviewer</p>
                            <div className="content">
                                {reviewedBy ? (
                                    <DetailedAvatar
                                        name={reviewedBy.displayName}
                                        avatarUrl={reviewedBy.avatarUrl}
                                        userName={reviewedBy.samlId}
                                        randomizeColor
                                        testId={`${testId}-${TestIds.ReviewDetailsReviewer}`}
                                    />
                                ) : (
                                    noDataRender()
                                )}
                            </div>
                        </div>
                        <div className="decision">
                            <p className="detail-title">Decision</p>
                            <div className="content">
                                <DecisionSelect
                                    testId={`${testId}-${TestIds.ReviewDetailsDecisionStatusSelector}`}
                                    onChangeDecision={handleUpdateDecision}
                                    decisionStatus={decisionStatus}
                                />
                            </div>
                        </div>
                    </div>

                    <div
                        className="access-roles"
                        data-testid={`${testId}-${TestIds.ReviewDetailsAccessRoles}`}
                    >
                        <div className="header">
                            <div className="header-title-container">
                                <Tooltip
                                    side="topLeft"
                                    content={
                                        expandRoles
                                            ? "Collapse Roles"
                                            : "Expand Roles"
                                    }
                                    variant="alt"
                                >
                                    <FontAwesomeIcon
                                        icon={expandRoles ? faMinus : faPlus}
                                        className="icon toggle-roles"
                                        onClick={handleToggleRoles}
                                        data-testid={`${testId}-${TestIds.ReviewDetailsToggleRolesButton}`}
                                    />
                                </Tooltip>

                                <p className="detail-title">Access Roles</p>
                            </div>

                            <div className="actions">
                                <Tooltip
                                    side="topRight"
                                    content={"Open Access Roles in GitHub"}
                                    variant="alt"
                                >
                                    <a
                                        href={`https://github.com/cpchem/${repositoryName}/settings/access`}
                                        rel="noopener noreferrer"
                                        target="_blank"
                                        data-testid={`${testId}-${TestIds.ReviewDetailsAddAccessRoleAnchor}`}
                                    >
                                        <FontAwesomeIcon
                                            icon={faUserPen}
                                            className={`icon add-role`}
                                        />
                                    </a>
                                </Tooltip>
                                <Tooltip
                                    side="topRight"
                                    content={
                                        isBeingUpdated
                                            ? "Roles are being refreshed."
                                            : "Refresh"
                                    }
                                    variant="alt"
                                >
                                    <FontAwesomeIcon
                                        icon={faArrowsRotate}
                                        className={`icon refresh-roles ${
                                            isBeingUpdated ? "disabled" : ""
                                        }`}
                                        data-testid={`${testId}-${TestIds.ReviewDetailsRefreshAccessRolesButton}`}
                                        role="button"
                                        tabIndex={0}
                                        onClick={refreshRoles}
                                        spin={isBeingUpdated}
                                    />
                                </Tooltip>
                            </div>
                        </div>
                        <div className="content">
                            <AccessRoles
                                repoCollaborators={repoCollaborators}
                                expandRoles={expandRoles}
                                testId={testId}
                            />
                        </div>
                    </div>
                    {comments && comments.length > 0 && (
                        <div
                            className="comments"
                            data-testid={`${testId}-${TestIds.ReviewDetailsComments}`}
                        >
                            <p className="detail-title">Comments</p>
                            <div className="content">
                                {comments.map(
                                    ({ addedBy, addedDate, comment }) => (
                                        <Comment
                                            addedBy={addedBy}
                                            addedDate={addedDate as string}
                                            comment={comment}
                                            testId={`${testId}-${TestIds.ReviewDetailsComment}`}
                                            key={`${addedDate}-${addedBy.displayName}`}
                                        />
                                    )
                                )}
                            </div>
                        </div>
                    )}

                    {isCommentTextAreaOpen && (
                        <div className="comments-input">
                            <CommentsInput
                                comment={comment}
                                onHandleChange={onCommentChange}
                                testId={`${testId}-${TestIds.ReviewDetailsCommentsInput}`}
                            />
                        </div>
                    )}
                </div>

                <div className="actions">
                    {isMobileScreen && (
                        <Button
                            className="go-back button"
                            variant="solid"
                            text="Back"
                            testId={`${testId}-${TestIds.ReviewDetailsMobileBackButton}`}
                            onClick={onClose}
                        />
                    )}
                    {isCommentTextAreaOpen && (
                        <Button
                            className="cancel-add-comment button"
                            variant="solid"
                            text="Cancel"
                            onClick={toggleCommentTextArea}
                            testId={`${testId}-${TestIds.ReviewDetailsCancelAddCommentButton}`}
                        />
                    )}
                    <Button
                        className="add-comment button"
                        variant="solid"
                        text="Add Comment"
                        color="primary"
                        testId={`${testId}-${TestIds.ReviewDetailsAddCommentButton}`}
                        onClick={
                            isCommentTextAreaOpen
                                ? handleAddComment
                                : toggleCommentTextArea
                        }
                        isDisabled={isCommentTextAreaOpen && !comment}
                    />
                </div>
            </div>
            <DecisionCommentModal
                isOpen={openDecisionCommentModal}
                onClose={handleCloseAddCommentModal}
                onAddComment={updateReviewDecisionWithComment}
                testId={TestIds.DecisionCommentModal}
            />
        </>
    );
}

export default ReviewDetails;
