import config from "@config";
import {
    ApiResponse,
    ServiceLocator,
    FetchInterceptorService,
    FetchServiceKey,
} from "@services";
import { FetchOptionMethod, FetchOptions } from "@services/fetch-interfaces";
import {
    CerberusService,
    GetCurrentCampaignAPIResponse,
    GetReviewsAPIResponse,
    CreateCampaignAPIRequest,
    CreateCampaignAPIResponse,
    UpdateReviewAPIRequest,
    UpdateReviewAPIResponse,
    RefreshReviewAPIRequest,
    RefreshReviewAPIResponse,
    AddCommentToReviewAPIRequest,
    AddCommentToReviewAPIResponse,
    GetReviewByIdAPIRequest,
    GetReviewByIdAPIResponse,
    GetReviewsAPIRequest,
    GetReviewerAPIResponse,
    GetClosedCampaignsAPIResponse,
    GetClosedCampaignDetailAPIResponse,
} from "./interface";
import { LogLevel, log } from "@cpchem/logging";
import {
    handle409InactiveCampaignResponse,
    handle409CreateCampaignResponse,
} from "./api-response";
import {
    ApiResponseWith404,
    errorResponseDictionary,
    errorResponseValue,
} from "@services/api-response";

const cerberusResponseDictionary: errorResponseValue = {
    ...errorResponseDictionary,
    409: handle409InactiveCampaignResponse,
};

const createCampaignResponseDictionary: errorResponseValue = {
    ...cerberusResponseDictionary,
    409: handle409CreateCampaignResponse,
};

function defaultErrorResponse(response: Response) {
    log(
        `Unknown error when attempting to retrieve data. Status: ${response.statusText}`,
        LogLevel.ERROR
    );
    return {
        error: response.statusText,
    };
}

export class CerberusServiceImplementation implements CerberusService {
    private readonly cerberusBase = config.api.cerberus.url;
    private readonly cerberusScopes = config.api.cerberus.scopes;

    interceptor: FetchInterceptorService;
    constructor() {
        this.interceptor =
            ServiceLocator.get<FetchInterceptorService>(FetchServiceKey);
    }

    private async ensureFetchOptionsAsync(
        method: FetchOptionMethod,
        body?: string | FormData
    ): Promise<FetchOptions> {
        return await this.interceptor.getFetchOptionsAsync(
            this.cerberusScopes,
            method,
            body
        );
    }

    async GetReviewer(): Promise<ApiResponse<GetReviewerAPIResponse>> {
        const uri = `${this.cerberusBase}/reviewer`;

        const options = await this.ensureFetchOptionsAsync("GET");

        const res = await fetch(uri, options);

        if (res.ok) {
            const json = await res.json();
            return {
                data: json,
            };
        }

        if (cerberusResponseDictionary[res.status]) {
            return cerberusResponseDictionary[res.status]();
        }
        return defaultErrorResponse(res);
    }

    async GetCurrentCampaign(): Promise<
        ApiResponse<GetCurrentCampaignAPIResponse>
    > {
        const uri = `${this.cerberusBase}/campaigns/current`;

        const options = await this.ensureFetchOptionsAsync("GET");

        const res = await fetch(uri, options);

        if (res.ok) {
            const json = await res.json();
            return {
                data: json,
            };
        }

        if (cerberusResponseDictionary[res.status]) {
            return cerberusResponseDictionary[res.status]();
        }
        return defaultErrorResponse(res);
    }

    async CreateCampaign({
        dueDate,
        file,
    }: CreateCampaignAPIRequest): Promise<
        ApiResponse<CreateCampaignAPIResponse>
    > {
        const uri = `${this.cerberusBase}/campaigns`;

        const formData = new FormData();
        formData.append("dueDate", dueDate.toISOString());
        formData.append("file", file);
        const options = await this.ensureFetchOptionsAsync("POST", formData);
        options.headers.delete("Content-Type");
        const res = await fetch(uri, options);

        if (res.ok) {
            return {
                data: {},
            };
        }

        if (createCampaignResponseDictionary[res.status]) {
            return createCampaignResponseDictionary[res.status]();
        }
        return defaultErrorResponse(res);
    }

    async CloseCurrentCampaign(): Promise<ApiResponse<undefined>> {
        const uri = `${this.cerberusBase}/campaigns/current`;

        const options = await this.ensureFetchOptionsAsync("PATCH");

        const res = await fetch(uri, options);

        if (res.ok) {
            return {};
        }
        if (cerberusResponseDictionary[res.status]) {
            return cerberusResponseDictionary[res.status]();
        }
        return defaultErrorResponse(res);
    }

    async GetReviews({
        campaignId,
        showAssigned,
    }: GetReviewsAPIRequest): Promise<ApiResponse<GetReviewsAPIResponse>> {
        const uri = `${this.cerberusBase}/campaigns/${campaignId}/reviews?showAssigned=${showAssigned}`;

        const options = await this.ensureFetchOptionsAsync("GET");

        const res = await fetch(uri, options);

        if (res.ok) {
            const json = await res.json();
            return {
                data: json,
            };
        }

        if (cerberusResponseDictionary[res.status]) {
            return cerberusResponseDictionary[res.status]();
        }
        return defaultErrorResponse(res);
    }

    async GetReviewById({
        campaignId,
        reviewId,
    }: GetReviewByIdAPIRequest): Promise<
        ApiResponseWith404<GetReviewByIdAPIResponse>
    > {
        const uri = `${this.cerberusBase}/campaigns/${campaignId}/reviews/${reviewId}`;

        const options = await this.ensureFetchOptionsAsync("GET");

        const res = await fetch(uri, options);

        if (res.ok) {
            const json = await res.json();
            return {
                data: json,
            };
        }

        if (res.status === 404) {
            log(
                `Data not found. Please check your request and try again.`,
                LogLevel.ERROR
            );
            return {
                isNotFound: true,
                error: "Data not found. Please check your request and try again.",
            };
        }

        if (cerberusResponseDictionary[res.status]) {
            return cerberusResponseDictionary[res.status]();
        }
        return defaultErrorResponse(res);
    }

    async UpdateReview({
        campaignId,
        reviewId,
        reviewUpdatePayload,
    }: UpdateReviewAPIRequest): Promise<ApiResponse<UpdateReviewAPIResponse>> {
        const uri = `${this.cerberusBase}/campaigns/${campaignId}/reviews/${reviewId}`;

        const options = await this.ensureFetchOptionsAsync(
            "PATCH",
            JSON.stringify(reviewUpdatePayload)
        );

        const res = await fetch(uri, options);

        if (res.ok) {
            return {
                data: {},
            };
        }

        if (cerberusResponseDictionary[res.status]) {
            return cerberusResponseDictionary[res.status]();
        }
        return defaultErrorResponse(res);
    }

    async RefreshReview({
        campaignId,
        reviewId,
    }: RefreshReviewAPIRequest): Promise<
        ApiResponse<RefreshReviewAPIResponse>
    > {
        const uri = `${this.cerberusBase}/campaigns/${campaignId}/reviews/${reviewId}/refresh`;

        const options = await this.ensureFetchOptionsAsync("POST");

        const res = await fetch(uri, options);

        if (res.ok) {
            return {
                data: {},
            };
        }

        if (cerberusResponseDictionary[res.status]) {
            return cerberusResponseDictionary[res.status]();
        }
        return defaultErrorResponse(res);
    }

    async AddCommentToReview({
        campaignId,
        reviewId,
        comment,
    }: AddCommentToReviewAPIRequest): Promise<
        ApiResponse<AddCommentToReviewAPIResponse>
    > {
        const uri = `${this.cerberusBase}/campaigns/${campaignId}/reviews/${reviewId}/comments`;

        const options = await this.ensureFetchOptionsAsync(
            "POST",
            JSON.stringify({ comment })
        );

        const res = await fetch(uri, options);

        if (res.ok) {
            return {
                data: {},
            };
        }

        if (cerberusResponseDictionary[res.status]) {
            return cerberusResponseDictionary[res.status]();
        }
        return defaultErrorResponse(res);
    }

    async GetClosedCampaigns(): Promise<
        ApiResponse<GetClosedCampaignsAPIResponse>
    > {
        const uri = `${this.cerberusBase}/campaigns/closed`;

        const options = await this.ensureFetchOptionsAsync("GET");

        const res = await fetch(uri, options);

        if (res.ok) {
            const json = await res.json();
            return {
                data: json,
            };
        }

        if (cerberusResponseDictionary[res.status]) {
            return cerberusResponseDictionary[res.status]();
        }
        return defaultErrorResponse(res);
    }

    async GetClosedCampaignDetail(
        id: string
    ): Promise<ApiResponse<GetClosedCampaignDetailAPIResponse>> {
        const uri = `${this.cerberusBase}/campaigns/${id}/closed`;

        const options = await this.ensureFetchOptionsAsync("GET");

        const res = await fetch(uri, options);

        if (res.ok) {
            const json = await res.json();
            return {
                data: json,
            };
        }

        if (cerberusResponseDictionary[res.status]) {
            return cerberusResponseDictionary[res.status]();
        }
        return defaultErrorResponse(res);
    }
}
